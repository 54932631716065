import React, { useEffect, useState, useContext } from 'react'
import {
  BodyWrapper as KycBodyWrapper,
  Button as PendingBtn,
  ButtonWrapper,
  FirstCol,
  Header,
  InputWrapper as KYCInputWrapper,
  MainInfoWrapper,
  PasswordStrength,
  ResetBtn,
  SecondCol,
  SubmitBtn,
} from 'src/components/udb/commonStyle';
import Intl from '../../../components/common/Intl';
import styled from 'styled-components';
import { BankInputField } from '../../../components/udb/Bankdetail';
import FileUpload, { FileView } from '../../../components/udb/kyc/FileUpload';
import CustomRadioButton from '../../../components/udb/kyc/CustomRadioButton';
import ProgressBar from '../../../components/udb/kyc/ProgressBar';
import { postKYCDetails, getKYCDetails } from '../../../config/api';
import config from 'src/config/config';
import { UserContext } from '../index';
import UDBHeader from 'src/components/UDBHeader';
import { Notify } from '../../../components/Notification'
import { theme } from 'styled-tools';
import SEOContents from 'src/components/SEOContents';

type documentTypeProps = {
  docType: '' | 'ADAR' | 'PAN' | 'PASSPORT' | 'DRIVING';
  docNumber: string;
  docNumberError?: boolean;
  isDisabled: boolean;
  status: 'PENDING' | 'APPROVED' | 'REJECTED';
  viewOnly: boolean;
  progress: '25' | '75' | '50' | '100';
}

type fsFileTypeProps = {
  isReq: boolean,
  file: Blob | null,
  type?: string;
}

const DocumentType = [
  {
    value: 'ADAR',
    label: 'Document@AadharCard',
    text: 'Aadhar Card'
  },
  {
    value: 'PAN',
    label: 'Document@PanCard',
    text: 'Pan Card'
  },
  {
    value: 'DRIVING',
    label: 'Document@DrivingLicense',
    text: 'Driving License'
  },
  {
    value: 'PASSPORT',
    label: 'Document@Passport',
    text: 'Passport'
  }
];

const defaultFileTypeState: fsFileTypeProps = {
  isReq: true,
  file: null,
  type: "image/png"
}

const defaultDocumentTypeState: documentTypeProps = {
  docType: '',
  docNumber: '',
  docNumberError: false,
  isDisabled: false,
  status: 'PENDING',
  viewOnly: false,
  progress: "25"
}

export default function KYCPage() {
  const [documentType, setDocumentType] = useState(defaultDocumentTypeState);
  const [fsFile, setfsFile] = useState(defaultFileTypeState);
  const [bsFile, setbsFile] = useState(defaultFileTypeState);
  const { userInfo } = useContext(UserContext);
  const [resetFile, setResetFile] = useState(false);
  const { uuid } = userInfo;

  //check if user has already submitted KYC
  useEffect(() => {
    if (uuid) {
      getKYCDetail();
    }
  }, [uuid]);

  const getKYCDetail = () => {
    getKYCDetails(uuid).then(req => {
      //check if DB has data 
      if (req.data.success) {
        const { data: { data = [] } } = req;
        if (data.length) {
          let fsStatus: any = {}
          let bsStatus: any = {}

          if (data.length === 1) {
            fsStatus = data[0]
          }
          else {
            fsStatus = data.find(elm => elm.side === 'FRONT');
            bsStatus = data.find(elm => elm.side === 'BACK');
          }

          const { filestatus, type, documentNumber, file } = fsStatus;
          const status = (filestatus || '').toUpperCase();
          const progress = status === 'APPROVED' ? "100" : "75";

          setDocumentType({
            docType: type.toUpperCase(),
            docNumber: documentNumber,
            isDisabled: !!type,
            status: status,
            viewOnly: !!documentNumber,
            progress: progress
          });

          setfsFile({ file: file[0], isReq: true, type: file[1] });
          if (Object.keys(bsStatus).length) {
            let bfile = bsStatus.file;
            setbsFile({ file: bfile[0], isReq: true, type: bfile[1] });
          }
          else {
            setbsFile({ file: null, isReq: false, type: "image/png" });
          }
        }
      }
    }).catch(e => console.log(e));
  }

  const documentTypeHandler = (e, value) => {
    setDocumentType(prevState => ({ ...prevState, docType: value }));
    setbsFile(preVProp => ({ ...preVProp, isReq: (value === 'ADAR' || value === 'DRIVING' || value === 'PASSPORT') }))
  }

  const documentNumberHandler = (e) => {
    let val = e.target.value;
    const _regEx = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?\s]+/; //spacial Chars
    if (!_regEx.test(val)) {
      setDocumentType(prevState => ({ ...prevState, docNumber: val, docNumberError: !val }));
    }
  }

  const fsDocUploadHandler = (f) => {
    setfsFile(prevS => ({ ...prevS, file: f }));
    setResetFile(false)
  }

  const bsDocUploadHandler = (f) => {
    setbsFile(prevS => ({ ...prevS, file: f }));
    setResetFile(false)
  }

  const validationcheck = () => {
    let retV = true;
    const { docType, docNumber, docNumberError, viewOnly } = documentType;
    if (viewOnly) {
      retV = true;
    }
    else if (!docType) {
      retV = false;
    }
    else if (!docNumber || docNumberError) {
      retV = false;
    }
    else if (fsFile.isReq && !fsFile.file) {
      retV = false;
    }
    else if (bsFile.isReq && !bsFile.file) {
      retV = false;
    }
    return retV;
  }

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validationcheck()) {
      const headers = {
        type: documentType.docType,
        number: documentType.docNumber
      }
      const formData = new FormData();
      formData.append('Ffile', fsFile.file);
      if (bsFile.isReq && bsFile.file) {
        formData.append('Bfile', bsFile.file);
      }

      postKYCDetails(formData, headers).then(res => {
        if (res.data.success) {
          Notify.success('Documents submitted successfully');
          getKYCDetail();
        }
      }).catch(e => {
        const { data } = e.response || {};
        let message = 'Something Went Wrong, Please Try Again'
        if (data && data.data.message) {
          message = data.data.message;

        }
        Notify.error(message);
      });
    }
  }

  const resubmitKYC = () => {
    setDocumentType(defaultDocumentTypeState);
    setfsFile(defaultFileTypeState);
    setbsFile(defaultFileTypeState);
  }

  const resetForm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    resubmitKYC();
    setResetFile(true)
  }

  const { docType, isDisabled, docNumber, docNumberError, viewOnly, status, progress } = documentType;
  const enableSubmit = validationcheck();
  const doctypeLabel = (DocumentType.find(elm => elm.value === docType) || {}).text || 'the Document';

  return (
    <MainInfoWrapper>
      <SEOContents pageName="memberProfileKYC"/>
      <Header>
        <FirstCol>
          <UDBHeader title="DASHBOARD@KYCVERIFICATION" />
        </FirstCol>

        <SecondCol>
          {status === 'REJECTED' && <TypeAgain onClick={resubmitKYC}>Try again</TypeAgain>}
          <Button className={status === 'APPROVED' ? 'approved' : (status === 'REJECTED' ? 'rejected' : '')}><Intl langKey="Kyc@status" /> {status}</Button>

          {status === 'APPROVED' && <ToolTipContainerApproved className="tooltipWrp">
            <p><Intl langKey="DASHBOARD@KYCThankMsgApproved" /></p>
            <span>
              <Intl langKey="dashboard@toolotip" />
            </span>
          </ToolTipContainerApproved>
          }
          {status === 'REJECTED' && <ToolTipContainerRejected className="tooltipWrp">
            <p><Intl langKey="DASHBOARD@KYCThankMsgRejected" /></p>
            <span>
              <Intl langKey="dashboard@toolotip" />
            </span>
          </ToolTipContainerRejected>
          }
          {status === 'PENDING' && progress !== "25" && <ToolTipContainer className="tooltipWrp">
            <p><Intl langKey="DASHBOARD@KYCThankMsg" /></p>
            <span>
              <Intl langKey="dashboard@toolotip" />
            </span>
          </ToolTipContainer>
          }
        </SecondCol>
      </Header>

      <ProcessSection>
        <ProgressBar percent={progress} />
      </ProcessSection>

      <BodyWrapper>
        <form onSubmit={(e) => submitHandler(e)}>
          <SubTittle>
            {!viewOnly ? <><Intl langKey="DASHBOARD@chooseDocs" /> <PasswordStrength><Intl langKey="DASHBOARD@chooseMinimumOne" /></PasswordStrength></> : <>Selected document</>
            }
          </SubTittle>

          <ChooseDocument className='error'>
            {DocumentType.map(elm => <CustomRadioButton key={elm.value} checked={elm.value === docType} disabled={isDisabled} name="documentType" label={<Intl langKey={elm.label} />} value={elm.value} onChange={documentTypeHandler} />)}
          </ChooseDocument>

          <SubTittle><Intl langKey="DASHBOARD@Docsdetail" /></SubTittle>
          <InputWrapper className={docNumberError ? 'error' : ''}>
            <BankInputField placeholder="Enter Document Number" readOnly={!!viewOnly} maxLength={30} value={docNumber} onChange={documentNumberHandler} name="documentNumber" req />
          </InputWrapper>

          <SubTittle>
            {!viewOnly ? <><Intl langKey="DASHBOARD@DocUpload" /> <PasswordStrength><Intl langKey="DASHBOARD@DocSize" /></PasswordStrength></> : <><Intl langKey="DASHBOARD@DocUploadAfter" /></>}
          </SubTittle>

          <Upload>
            {viewOnly ? <FileView file={fsFile.file} /> : <FileUpload name="fsdoc" isReset={resetFile} label={`${bsFile.isReq ? 'Front side of' : ''} ${doctypeLabel} (Clear image)`} isReq={fsFile.isReq} onUpload={fsDocUploadHandler} maxSizeMB={6} isDeposit={false} />}

            {bsFile.isReq && <>
              <DashLine />
              {viewOnly ? <FileView file={bsFile.file} /> : <FileUpload name="bsdoc" isReset={resetFile} label={`Back side of ${doctypeLabel} (Clear image)`} isReq={bsFile.isReq} value={bsFile.file} onUpload={bsDocUploadHandler} maxSizeMB={6} isDeposit={false} />}
            </>}
          </Upload>

          {!viewOnly && <ButtonWrapper>
            <SubmitBtn type='submit' disabled={!enableSubmit}><Intl langKey="DASHBOARD@DocSubmit" /></SubmitBtn>
            <ResetBtn onClick={resetForm}><Intl langKey="DASHBOARD@DocReset" /></ResetBtn>
          </ButtonWrapper>}
        </form>
      </BodyWrapper>

    </MainInfoWrapper>
  )
}

//Styled Components
export const BodyWrapper = styled(KycBodyWrapper)`
  width: 100%;
  flex-direction: column;
  align-items: flex-start;

  form {
      width: 70%;
  }
`;
const Button = styled(PendingBtn)`
    align-items: center;
    justify-content: center;
    background: ${theme('palette.kycBtnBg')};
    opacity: 0.8;
    border: ${theme('palette.kycBtnBorder')};
    color: ${theme('palette.kycBtnTextColor')};
    text-transform: uppercase;
    font-size: .75rem;
    padding-right: 4em;
    padding-left: 1em;
    background-repeat: no-repeat;
    background-position: center;
    background-size: ${theme('palette.kycBtnBgSize')};
    &:hover ~ .tooltipWrp { display:block}

    :after {
            content: "";
            width: 38px;
            height: 100%;
            display: flex;
            position: absolute;
            right: 0;
            top: 0;
            background: ${theme('palette.kycBtnBgAfter')};
            border-radius: 4px;
            background-image: url(${config.imageV3Host}/static/cashsite/${theme('image.statusIconPending')});
            background-position: center;
            background-size: 50%;
            background-repeat: no-repeat;
        }

`;
const DashLine = styled.div`
  width: 0px;
  height: 150px;
  display: flex;
  margin: auto 10px;
  border: 1px dashed rgba(138, 158, 197, 0.97);
`;
export const Upload = styled.div`
  width: 100%;
  display: flex;
  // align-items: center;
  padding: 0.5rem 0rem 1.5rem 0rem;
  background: #00000069;
  border-radius: 5px;
  border: 1px solid #d8cbcb;
  margin-bottom: 10px;
  min-height: 125px;

  & svg {
        z-index: 999;
    }
    & span {
        z-index: 999;
    }
`;
const InputWrapper = styled(KYCInputWrapper)`
  width: 60%;
  > label { margin-top:0}
  &.error > label { border:1px solid red;    background: #efdfdf; color: #353535;}
`;

export const ChooseDocument = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;
`;


export const SubTittle = styled.div`
  display: flex;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  color: #FFFFFF;
  font-weight: 600;
  font-size: 12px;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
`;
const Content = styled.li`
  display: flex;
  list-style: none;
  color: #BBB8ED;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.01em;
  text-transform: capitalize;
  padding: 10px 5px;
`;
const ProcessSection = styled.ul`
  display: flex;
  margin: 0;
  align-items: center;
  justify-content: space-around;
  padding: 0;
`;

export const ToolTipContainer = styled.div`
  background: ${theme('palette.kycToolTipPendingTop')};
  position: absolute;
  top: 45px;
  right: 0;
  width: 181px;
  font-size: 9px;
  border-radius: 3px;
  line-height:1.3;
  display:none;
  color: ${theme('palette.kycToolTipColor')};

  > p {
    padding:5px 9px;
    margin:0px
  }

  span {
    width:100%;
    display:block;
    background: ${theme('palette.kycToolTipPendingBottom')};
    padding:5px 9px;
  }
`
export const ToolTipContainerApproved = styled.div`
  background: ${theme('palette.kycToolTipApprovedTop')};
  position: absolute;
  top: 45px;
  right: 0;
  width: 181px;
  font-size: 9px;
  border-radius: 3px;
  line-height:1.3;
  color: #fff;
  display:none;

  > p {
    padding:5px 9px;
    margin:0px
  }

  span {
    width:100%;
    display:block;
    background: ${theme('palette.kycToolTipApprovedBottom')};
    padding:5px 9px;
  }
`;

export const ToolTipContainerRejected = styled.div`
  background: ${theme('palette.kycToolTipRejectedTop')};
  position: absolute;
  top: 45px;
  right: 0;
  width: 181px;
  font-size: 9px;
  border-radius: 3px;
  line-height:1.3;
  color: #fff;
  display:none;

  > p {
    padding:5px 9px;
    margin:0px
  }

  span {
    width:100%;
    display:block;
    background: ${theme('palette.kycToolTipRejectedBottom')};
    padding:5px 9px;
  }
`;

const TypeAgain = styled.span`
  font-size: .70rem;
  line-height: 11px;
  letter-spacing: 0.02em;
  text-decoration-line: underline;
  color: #FFFFFF;
  display:inline-block;
  margin-right:10px;
  cursor:pointer;
`