import React, { useState, useContext, useEffect, useRef } from 'react';
import {
    ButtonWrapper as DepoButtonWrapper,
    FirstCol,
    Header,
    MainInfoWrapper,
    PasswordStrength,
    ResetBtn as DepoResetBtn,
    SubmitBtn as DepoSubmitBtn,
    SvgIcon,
} from 'src/components/udb/commonStyle';
import { BodyWrapper as DepositBodyWrapper, SubTittle, ChooseDocument, Upload } from '../profile/KYCPage';
import Intl from '../../../components/common/Intl';
import styled, { withTheme } from 'styled-components';
import CustomRadioButton from '../../../components/udb/kyc/CustomRadioButton';
import { BankInputField, CryptoCurrencyDropDown } from '../../../components/udb/Bankdetail';
import PaymentProviderRadioButton from '../../../components/udb/kyc/PaymentProviderRadioButton';
import { UserContext } from '../index';
import { postDeposit, getChannelType, getCorporateBank, postManualDeposit, VerifyCouponCode } from 'src/config/api';
//import { useNavigate } from 'react-router-dom';
import UDBHeader from 'src/components/UDBHeader';
import config from "src/config/config";
import { withAppContext } from 'src/store/initAppContext';
import { Notify } from '../../../components/Notification';

//for manual deposit
import FileUpload from 'src/components/udb/kyc/FileUpload';
import { copyToClipboard, Copy } from '../profile/AccountInfo';
// import { TriggerPxl, TriggerPxlParticular } from 'src/pages/auth/Signup/Signup';
// import FastUpiDepositSteps from 'src/components/icon/FastUpiDepositSteps';
import QRCode from "react-qr-code";
import { Player } from '@lottiefiles/react-lottie-player';
// import FastBankDepositSteps from 'src/components/icon/FastBankDepositSteps';
import CustomRadioDepositButton from 'src/components/udb/kyc/CustomRadioDepoitButton';

// import RegularDepositIcon from 'src/components/icon/RegularDepositIcon';
import { createWorker } from "tesseract.js";
import { theme, prop } from 'styled-tools';
import UpiScannerIcon from 'src/components/icon/UpiScannerIcon';
import DepositCloseIcon from 'src/components/icon/DepositCloseIcon';
// import FastUpiDepositStepsIcon from 'src/components/icon/FastUpiDepositStepsIcon';
import DepositDetailsUpiIcon from 'src/components/icon/DepositDetailsUpiIcon';
import TimerDeposit from 'src/components/TimerCounter';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SEOContents from 'src/components/SEOContents';
import { CRMDepPaymentMethodClick, CRMDepositChannelClick, CRMDepositSubmitClick } from 'src/util/crmUtil';
import { TickIcon, CrossIcon } from 'src/components/icon/coupon';
import { OtpButton, PhoneNumberAndOtpWrap } from 'src/components/Auth/commonStyles';
import DepositDetailsUpiIconFastUpi from 'src/components/icon/DepositDetailsUpiIconFastUpi';
// import FastUpiDepositIcon from 'src/components/icon/FastUpiDepositIcon';
// import FastBankDepositIcon from 'src/components/icon/FastBankDepositIcon';
// import RegularUpiDepositIcon from 'src/components/icon/RegularUpiDepositIcon';
// import NetbankingDepositIcon from 'src/components/icon/NetbankingDepositIcon';
import { Divider as DepoDivider } from 'src/pages/promotions/FomoWelcomeBonus';
import { SpecialChannelWarning,SpecialChannelWarningBaazi } from 'src/components/icon/SpecialChannelWarning';
import CryptoDepositIcon from 'src/components/icon/CryptoDepositIcon';
// import ExtraBounsImage from 'src/components/icon/ExtraBounsImage';

export const depositSigmentPxl = (marketingSource: any, view = true, value: any = 0) => {
    if (marketingSource && marketingSource.toLowerCase() === "channel377") {
        let d = document;
        let s = "script";
        let _src = view ? "https://secure.adnxs.com/seg?add=32940185&t=1" : `https://secure.adnxs.com/px?id=1662253&seg=32940180&other={{in:${value}}}&t=1`
        let scr: any = d.createElement(s);
        scr.type = "text/javascript";
        scr.async = true;
        scr.src = _src;
        let x = d.getElementsByTagName(s)[0];
        x.parentNode.insertBefore(scr, x);
    }
}

type DepositStyleProps = {
    scannerDisplay: boolean;
}

type DepositProps = {
    methodId: string;
    currencyCode: string;
    countryCode: string;
    amount: string;
    paymentGatewayId: string;
    EWData: any;
    methodType: 'FUPI' | 'UPI' | 'NB' | 'EW' | 'MD' | 'CRYP' | '';
    transactionId: string;
    transactionImg: Blob | null;
}

type BankProps = {
    brand_id: number;
    bankName: string;
    accountHolderName: string;
    accountNumber: string;
    ifscCode: string;
    upiId: string;
    uuid: string;
}

// type corporateBankDetailsProps = {
//     ifscCode: string;
//     accountHolderName: string;
//     accountNumber: string;
//     bankName: string;
//     uuid: string;
//     upiId: string
// }

type corporateBankDetailsProps = {
    bank_details: BankProps[];
    upi_details: BankProps[];
}

type cryptoProps = {
    "currency":string;
    "exchangeRate":number
}


const defaultState: DepositProps = {
    methodId: '',
    currencyCode: 'INR',
    countryCode: 'IN',
    amount: '',
    paymentGatewayId: '',
    methodType: '',
    EWData: {
        exp_date: '',
        cvv: '',
        card_num: ''
    },
    transactionId: '',
    transactionImg: null
}

const paymentProviderType = [
    {
        value: '2dbf67d090564e4aa7d2d3b54e5ed237',
        label: 'Astropay',
        icon: 'astropay',
    },
    // {
    //     value: 'c1e52b38665c4d2181b712df778cc11f',
    //     label: 'Neteller',
    //     icon: 'neteller',
    // },
    // {
    //     value: 'd623c16178ed46318e5cbaa342935921',
    //     label: 'Skrill',
    //     icon: 'skrill',
    // }
];

const defaultErrorState = {
    methodId: false,
    amount: false,
    paymentGatewayId: false,
    exp_date: false,
    cvv: false,
    card_num: false,
    message: '',
    transactionId: false,
    transactionImg: false
}

const ChannelLabel = ({ data }) => {
    return (<>
        {data.label}
        <SecondaryText> 
            <Intl langKey='Dashboard@SubTxt' /> 
             <>₹{data.minLimit} / ₹{data.maxLimit}</></SecondaryText>
    </>)
}

const getTransId = (text) => {
    let txtArr = text.split('\n');
    let transactionId = '';

    let findIndex = txtArr.findIndex(elm => (
        elm.includes('Transaction ID') ||
        elm.includes('UPI transaction ID') ||
        elm.startsWith('Reference No.') ||
        elm.includes('(UTR No./RRN)')
    ));

    if (findIndex !== -1 && (txtArr.findIndex(elm => elm.includes('UTR:')) === -1) && (txtArr.findIndex(elm => elm.includes('Bank Reference')) === -1)) {
        transactionId = txtArr[findIndex + 1]
    }
    else {
        findIndex = txtArr.find(elm => (
            elm.startsWith('Reference') ||
            elm.startsWith('UPI Ref No:') ||
            elm.startsWith('UTR:') ||
            elm.startsWith('Ref.No.') ||
            elm.startsWith('Bank Reference')
        ));

        if (findIndex) {
            if (findIndex.includes('Bank Reference')) {
                transactionId = findIndex.split(':')[1]
            }
            else if (findIndex.includes('Reference Number')) {
                transactionId = findIndex.split('Reference Number ')[1]
            }
            else if (findIndex.includes('Reference ID')) {
                transactionId = findIndex.split('Reference ID ')[1]
            }
            else if (findIndex.includes('Reference')) {
                transactionId = findIndex.split('Reference ')[1]
            }
            else if (findIndex.includes('UPI Ref No:')) {
                transactionId = findIndex.split('UPI Ref No: ')[1]
            }
            else if (findIndex.includes('UTR:')) {
                transactionId = findIndex.split('UTR: ')[1]
            }
            else if (findIndex.includes('Ref.No..')) {
                transactionId = findIndex.split('Ref.No.. ')[1]
            }
        }
    }

    transactionId = transactionId ? transactionId.trim() : '';
    transactionId = transactionId.split(' ')[0];
    return /\*?\d+/.test(transactionId) ? transactionId : '';
}

// const getTransId = (text) => {
//     let txtArr = text.split('\n');
//     let transactionId = '';

//     let findIndex = txtArr.findIndex(elm => elm.includes('UTR: ') || elm.includes('UPI transaction'));
//     if (findIndex !== -1) {
//         if(txtArr.find(element => (element.startsWith('UPI transaction ID')))){
//             transactionId = txtArr[findIndex + 1]
//         } else {
//             transactionId = txtArr[findIndex].split(":")[1]
//         }
//     }
//     else {
//         findIndex = txtArr.find(element => (
//             element.startsWith('UTR: ') ||
//             element.startsWith('UPI transaction ID: ') ||
//             element.startsWith('Transaction ID: ') ||
//             element.startsWith('UPI Ref No: ')
//         ));

//         if (findIndex) {
//             if (findIndex.includes('UTR: ')) {
//                 transactionId = findIndex.split(":")[1]
//             }
//             else if (findIndex.includes('UPI transaction ID: ')) {
//                 transactionId = findIndex.split(':')[1]
//             }
//             else if (findIndex.includes('UPI Ref No: ')) {
//                 transactionId = findIndex.split('UPI Ref No: ')[1]
//             }
//             else if (findIndex.includes('Transaction ID: ')) {
//                 transactionId = findIndex.split('Transaction ID: ')[1]
//             }
//         }
//     }

//     transactionId = transactionId.trim();
//     transactionId = transactionId.split(' ')[0];
//     return /\*?\d+/.test(transactionId) ? transactionId : '';
// }

const AccountHolderComponent = ({ langKey, accountData, copyIcon, copyCallBack }) => {
    return (<AccountHolder>
        <h6><Intl langKey={langKey} /></h6>
        <Copy onClick={() => copyCallBack(accountData)} >
            <AccountDetailsWrap>
                <p className="bankDetailAccNumber">{accountData}</p>
                <Player
                    autoplay
                    loop
                    controls
                    src={`${config.imageV3NonOptHost}/static/cashsite/${copyIcon}`}
                    style={{ height: '50px', width: '50px' }}
                ></Player>
            </AccountDetailsWrap>
        </Copy>
    </AccountHolder>)
}

const DepositBankDetailComponent = (props) => {
    const { data, copyHandler, option, uuid, theme, onSelect } = props;
    const { config: { showTextInside } } = theme;
    const isChecked = uuid === data.uuid;
    return (
        <ManualDepositBankDetails className={`pos-${option} ${isChecked ? 'classBorderChecked' : 'classBorder'}`}>
            {/* {showTextInside && (<TextInside><Intl langKey='MANUALDEPOSIT@YoloBankNew' /></TextInside>)} */}
            <FastBankUpiBgTexts>
                <div className="optionSec">
                    <p>{option + 1}</p>
                    <DepositDetailsUpiIcon />
                </div>
                <div>
                    {/* <input type="checkbox" name="upiDetailSelect" id="" checked={uuid === data.uuid} /> */}
                    <input type="checkbox" name="upiDetailSelect" id="" checked={isChecked} onChange={() => {}} />
                </div>
            </FastBankUpiBgTexts>
            <div className="fastBankDetailsCopy">

                <div className="fastBankDetailsCopyInner">
                    <AccountHolderComponent
                        langKey='MANUALDEPOSIT@YoloBankAcc'
                        accountData={data.accountNumber}
                        copyIcon={theme.image.depositCopyGreenIcon}
                        copyCallBack={copyHandler}
                    />

                    <AccountHolderComponent
                        langKey='MANUALDEPOSIT@YoloBankName'
                        accountData={data.bankName}
                        copyIcon={theme.image.depositCopyGreenIcon}
                        copyCallBack={copyHandler}
                    />
                </div>

                <div className="fastBankDetailsCopyInner">
                    <AccountHolderComponent
                        langKey='MANUALDEPOSIT@YoloBankAccHolder'
                        accountData={data.accountHolderName}
                        copyIcon={theme.image.depositCopyGreenIcon}
                        copyCallBack={copyHandler}
                    />

                    <AccountHolderComponent
                        langKey='MANUALDEPOSIT@YoloIFSC'
                        accountData={data.ifscCode}
                        copyIcon={theme.image.depositCopyGreenIcon}
                        copyCallBack={copyHandler}
                    />
                </div>
            </div>
            {data.uuid !== uuid && <OptOverlay onClick={() => onSelect(data.uuid)}><Intl langKey='MANUALDEPOSIT@SelectOption' /> - 0{option + 1}</OptOverlay>}
        </ManualDepositBankDetails>
    )
}

const DepositBankUPIComponent = (props) => {

    const { data, option, uuid, onSelect, channel, openQRCodeHandler, theme, copyHandler, brandId } = props;
    const { config: { showTextInside } } = theme;
    //upi scanner tooltip start

    const upiQrUrl = `upi://pay?pa=${data.upiId}&pn=Yolo247&cu=INR`;

    return (
        <ManualDepositUPIDetails>
            {/* { showTextInside && (<TextInside><Intl langKey='MANUALDEPOSIT@UPINEW' /></TextInside>)} */}
            <AccountHolderWrp >
                <FastBankUpiBgTexts>
                    <div className="optionSec">
                        <p>{option + 1}</p>
                        <DepositDetailsUpiIconFastUpi />
                    </div>
                    <div>
                        <input type="checkbox" name="upiDetailSelect" id="" checked={uuid === data.uuid} />
                    </div>
                </FastBankUpiBgTexts>
                <AccountHolder>
                    <AccountDetailsWrap>
                        {/* <Intl langKey='MANUALDEPOSIT@UPI' /> */}
                        {data.upiId}
                        <Copy onClick={() => copyHandler(data.upiId)} >
                            <Player
                                autoplay
                                loop
                                controls
                                src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.depositCopyGreenIcon}`}
                                style={{ height: '50px', width: '50px' }}
                            ></Player>
                        </Copy>
                    </AccountDetailsWrap>
                    <div onClick={() => openQRCodeHandler(data.upiId)}>

                        <UpiScannerWrapper>
                            <p><Intl langKey="MANUALDEPOSIT@UPIOption" /></p>
                            <SvgIcon>
                                <UpiScannerIcon />
                            </SvgIcon>
                        </UpiScannerWrapper>
                    </div>

                </AccountHolder>
            </AccountHolderWrp>
            {data.uuid !== uuid && <OptOverlay onClick={() => onSelect(data.uuid)}><Intl langKey='MANUALDEPOSIT@SelectOption' /> - 0{option + 1}</OptOverlay>}
        </ManualDepositUPIDetails>
    )
}

const couponCodeDefaultState = {
    promoCode: '',
    appliedPromoCode: '',
    showPromoMsg: '',
    isInvalid: false,
    isActive: false,
    showEdit: false,
    // ssError:false,
    blockSubmit: false
}

const defaultSelectedCurrency :cryptoProps = {
    currency:"",
    exchangeRate:0
}

function Deposit(props) {
    const [depositState, setDepositState] = useState(defaultState);
    const [errorState, setErrorState] = useState(defaultErrorState);

    const [channelsType, setChannelsType] = useState({}); //placeholder for all channels 
    const [ChannelType, setChannelType] = useState([]);
    const [depositeType, setDepositeType] = useState([]);
    const [noDataMsg, setNoDataMsg] = useState(false);
    const [corporateBankDetails, setCorporateBankDetails] = useState({} as corporateBankDetailsProps)

    const marketingSource = sessionStorage.getItem("sessionMarketSource") || "";//searchParams.get('marketingSource') || '';
    const [bankuuid, setBankuuid] = useState('');
    const [upiuuid, setUpiuuid] = useState('');
    const [resetFile, setResetFile] = useState(false);
    const [showTimer, setShowTimer] = useState(true)
    const [showMDError, setShowMDError] = useState(false);

    const [scannerState, setScannerState] = useState(false);
    const [qrCodeId, setQRCodeId] = useState('');
    const qrWrapperRef = useRef<HTMLDivElement>(null);

    const [showChannelMsg, setShowChannelMsg] = useState(false);

    //for coupon code
    const [showPromoCode, setShowPromoCode] = useState(false);
    const [couponState, setCouponState] = useState(couponCodeDefaultState);

    //for Crypto
    const [cryptocurrency, setCryptocurrency] = useState<cryptoProps[]>([]) //drop down 
    const [currencyValue, setCurrencyValue] = useState(defaultSelectedCurrency) // value and exchange rate based on DD selection
    const [cryptoChannelType, setCryptoChannelType] = useState([]);

    //const navigate = useNavigate();
    const { userInfo } = useContext(UserContext);
    
    const depositeUrl = '/member/finance/deposit';
    const { app: { brandId ,isMobileWidth}, theme } = props;

    const _depositeType = [
        {
            value: '101',
            label: 'Payment@MD',
            methodType: 'MD',
            icon: theme.image.FastBankDepoIconNew,
            topChoice: true,
            id: "deposit_fastdeposit",
            isDefault: true
        },
        {
            value: '102',
            label: 'Paymnt@Fast-UPI',
            methodType: 'FUPI',
            icon: theme.image.FastUpiDepoIconNew,
            topChoice: true,
            id: "deposit_fastUPIdeposit",
            isDefault: true
        },
        {
            value: '2',
            label: 'Payment@UpiPayment',
            methodType: 'UPI',
            icon: theme.image.RegularUpiDepoIconNew,
            topChoice: false,
            id: "deposit_upipg",
            isDefault: true
        },
        {
            value: '4',
            label: 'Payment@Crypto',
            methodType: 'CRYP',
            // icon: <CryptoDepositIcon />,
            icon: theme.image.CryptoDepositIconNew,
            topChoice: false,
            id: "deposit_crypto",
            isDefault: true
        },
        {
            value: '1',
            label: 'Payment@NetBanking',
            methodType: 'NB',
            icon: theme.image.NetbankingDepoIconNew,
            topChoice: false,
            id: "deposit_netbanking",
            isDefault: true
        },
        // {
        //     value: '3',
        //     label: 'Paymnt@E-wallet',
        //     methodType: 'EW',
        //     id: "deposit_EWallet",
        //     isDefault: true
        // },
    ];
  
    const worker = createWorker({
        logger: (m) => {
            //console.log(m);
        },
    });

    let deviceToken = useRef(null);

    //need time to show 400% bonus snippet
    const { created, is_ftd_requested , is_ftd_done, tier, redeposit_percentage} = userInfo;

    useEffect(() => {
        convertImageToText();
    }, [depositState.transactionImg]);

    useEffect(() => {
        //calling API for Channel
        getChannelType('DEPOSIT',isMobileWidth?1:2).then(res => {
            // const {  data = []  } = tempData;
            const { data: { data = [] } } = res;
            let cryptoCurrency = []

            if (data.length) {
                let tempChannels = {};
                data.forEach(element => {
                    //Adding method id
                    const methodId = (element.method_ids || '').split(',');
                    if (methodId.length) {
                        methodId.forEach(id => {
                            if (!tempChannels.hasOwnProperty(id)) {
                                tempChannels[id] = [element];
                            }
                            else {
                                let existingId = tempChannels[id];
                                tempChannels[id] = [...existingId, element];
                            }

                            //Storing Crypto Currency
                            if(element.crypto && element.crypto.length>0){
                                cryptoCurrency = [...cryptoCurrency, ...element.crypto]
                            }
                        })
                    }
                });

                const methodType = Object.keys(tempChannels);
                const depositeMethods = _depositeType.filter(elm => {
                    return methodType.includes(elm.value);
                });
                
                const _default: any = depositeMethods.length === 1 ? depositeMethods[0] : depositeMethods.find(elm => elm.isDefault === true);
                setNoDataMsg(false);
                setDepositeType(depositeMethods);
                setChannelsType(tempChannels);
                if (_default) {
                    const _defValue = _default.value;
                    const selectedChannels = tempChannels[_defValue];
                    //we need to add Payment getway id for Fast UPI
                    let findcurrentgetway:any = {};
                    if(_defValue === '101'  || _defValue === '102'){
                        findcurrentgetway = selectedChannels.find(elm => elm.method_ids === _defValue) || {};
                    }
                    const paymentGatewayId = findcurrentgetway && findcurrentgetway.value ? findcurrentgetway.value : '';
                    setDepositState(prvS => ({ ...prvS, methodType: _default.methodType, methodId: _defValue, paymentGatewayId }));
           
                    setChannelType(selectedChannels)
                }

                //Storing Crypto Currency
                if(cryptoCurrency && cryptoCurrency.length>0) {
                    let uniqCurrency = cryptoCurrency.reduce((accum, currV)=> {
                       if(!accum.some(elm=>elm.currency === currV.currency)) {
                        accum.push(currV);
                       }
                       return accum;
                    },[]);

                    setCryptocurrency(uniqCurrency);
                }

            }
            else {
                setNoDataMsg(true)
            }

        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again';
            if (data && data.error) {
                message = data.error;

            }
            Notify.error(message);
            setNoDataMsg(true)
        });

        getCorporateBankDetails();
        // TriggerDeposit(true);

        // for Sigment Pixel 
        if (brandId === 31) {
            depositSigmentPxl(marketingSource, true);
        }
        //to show deposit timer
        setShowTimer(is_ftd_requested);
    }, []);

    //ADDED CODE FOR DEVICE FINGERPRINT
    useEffect(() => {
        try {
            let deviceId = localStorage.getItem('dId');
            if (!deviceId) {
                const _seon = window.seon || '';
                if (typeof _seon === 'object') {
                    //configration part
                    _seon.config({
                        host: "seondf.com",
                        session_id: Math.random().toString(36).substring(2),
                        audio_fingerprint: true,
                        canvas_fingerprint: true,
                        webgl_fingerprint: true,
                        onSuccess: function (message) {
                            console.log("success", message);
                        },
                        onError: function (message) {
                            console.log("error", message);
                        }
                    });

                    //generate device key
                    _seon.getBase64Session(function (data) {
                        if (data) {

                            deviceId = data;
                            deviceToken.current = data;
                            localStorage.setItem('dId', data);
                        }
                        else {
                            console.log("Failed to retrieve session data.");
                        }
                    })
                }
            }

            deviceToken.current = deviceId;

        } catch (error) {
            console.log("Failed to generate FP token");
            return;
        }

    }, []);

    const togglePromoCode = (event:any) => {
        setShowPromoCode(!showPromoCode);
        setCouponState(couponCodeDefaultState);
        //event.stopPropagation()
       //e.preventDefault() 
    }

    const ResetCouponCode = () => {
        setCouponState(couponCodeDefaultState)
    }

    const CouponCodeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /\W|_/g;
        const preValue = couponState.promoCode;
        let val = e.target.value;
        val = val ? (regExp.test(val) ? preValue : val) : '';
        setCouponState(preState => ({
            ...preState,
            promoCode: val,
            showPromoMsg: '',
            isInvalid: false,
            blockSubmit: !!val,
            isActive: !!val
        }));
    }

    const validateCouponCode = () => {
        const { promoCode, appliedPromoCode } = couponState;
        const { amount } = depositState;
        if (!amount) {
            setErrorState({ ...defaultErrorState, amount: true, message: 'Enter Deposit Amount' });
        }
        else {
            if (promoCode) {
                if (promoCode.length < 2 || promoCode.length > 20) {
                    setCouponState(preState => ({
                        ...preState,
                        isInvalid: true,
                        showPromoMsg: 'Invalid promo code'
                    }));
                    return false
                }
                else if (promoCode !== appliedPromoCode) {
                    setCouponState(preState => ({
                        ...preState,
                        appliedPromoCode: promoCode,
                        isActive: false
                    }));

                    VerifyCouponCode(promoCode, amount)
                        .then(res => {
                            const { data: { message, success } } = res;
                            //Congratulations, You have won Amount as bonus.
                            let _message = success ? 'Congratulations, Your promo code has been successfully applied' : '';
                            if (message && message.FIXED) {
                                const Amount = parseFloat(message.FIXED);
                                if (Amount) {
                                    _message = `Congratulations, You have won ₹${Amount} as bonus.`
                                }
                            }
                            setCouponState(preState => ({
                                ...preState,
                                showPromoMsg: _message,
                                isInvalid: !success,
                                isActive: true, //Let it true, but it will not req in general case
                                showEdit: true,
                                // ssError:false,
                                blockSubmit: false
                            }));
                        })
                        .catch(e => {
                            const { data } = e.response || {};
                            let message = 'Something Went Wrong, Please Try Again'
                            if (data && data.error) {
                                setCouponState(preState => ({
                                    ...preState,
                                    showPromoMsg: 'This promo code is invalid or expired',
                                    isInvalid: true,
                                    isActive: true,
                                    showEdit: false,
                                    //ssError:true
                                }));
                            }
                            else {
                                Notify.error(message);
                                setCouponState(preState => ({
                                    ...preState,
                                    isActive: true,
                                    showEdit: false
                                    //ssError:true
                                }));
                            }
                        })
                }
            }
            else {
                setCouponState(preState => ({
                    ...preState,
                    isInvalid: true,
                    showPromoMsg: 'Please enter a valid promo code'
                }));
                return false
            }
        }

    }

    // for manual deposit
    const getCorporateBankDetails = () => {
        getCorporateBank().then(res => {
            const { data: { data = {} } } = res;
            const { bank_details = [], upi_details = [] } = data;
            setCorporateBankDetails(data);
            setDefaultUUID(data);

            //To Avoid showing the default error message adding an extra check to show Manual Deposite error message
            // now we will wait for API response and then show message accordingly
            if (bank_details.length <= 0 || upi_details.length <= 0) {
                setShowMDError(true)
            }
        }).catch(e => {
            const { data } = e.response || {};
            let message = 'Something Went Wrong, Please Try Again'
            if (data && data.error) {
                message = data.error;
            }
            Notify.error(message);
            setShowMDError(true);
        });
    }

    const setDefaultUUID = (data) => {
        const { bank_details = [], upi_details = [] } = data;
        //set the first record as Default UUID
        if (bank_details.length) {
            setBankuuid(bank_details[0].uuid);
        }
        if (upi_details.length) {
            setUpiuuid(upi_details[0].uuid);
        }
    }

    const paymentTypeHandler = (e: React.ChangeEvent<HTMLInputElement>, methodType) => {
        const _val = e.target.value;
        const selectedChannelType = channelsType[_val];
        setChannelType(selectedChannelType);
        setDepositState(prvS => ({ ...prvS, methodType, methodId: _val, paymentGatewayId: (methodType === 'MD' || methodType === 'FUPI') ? selectedChannelType[0].value : '', EWData: (methodType === 'EW' ? prvS.EWData : defaultState.EWData) }));
        //for CRM
        CRMDepPaymentMethodClick(brandId, _val)
        setShowChannelMsg(false);
        //Hide Promo Code also
        setShowPromoCode(false)
        setCouponState(couponCodeDefaultState)
    }

    const depositAmountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regExp = /^\d+$/;
        const preValue = depositState.amount;
        let val = e.target.value;
        val = val ? (regExp.test(val) ? val : preValue) : '';
        setDepositState(prvS => ({ ...prvS, amount: val }));
        setErrorState(prevS => ({ ...prevS, amount: !val }))
    }

    const channelSelecHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDepositState(prvS => ({ ...prvS, paymentGatewayId: e.target.value }))
        setShowChannelMsg(!!e.target.value);
         //for CRM
         CRMDepositChannelClick(brandId, depositState.methodId, depositState.amount, e.target.value);
    }

    const validationCheck = () => {
        let retV = true;
        const { methodId, amount, paymentGatewayId, methodType, EWData: { exp_date, cvv, card_num }, transactionId, transactionImg } = depositState;
        const expRegEx = /^\d{2}\-\d{4}$/;
        const cvvRegEx = /^[0-9]{3,4}$/;

        if (!methodId || !methodType) {
            setErrorState({ ...defaultErrorState, methodId: true, message: 'Min One Selection Required' });
            retV = false;
        }
        else if (!amount) {
            setErrorState({ ...defaultErrorState, amount: true, message: 'Enter Deposit Amount' });
            retV = false;
        }
        else if (!paymentGatewayId) {
            setErrorState({ ...defaultErrorState, paymentGatewayId: true, message: 'Choose One Channel' });
            retV = false;
        }

        if (methodType && methodType === 'EW') {
            if (!card_num) {
                setErrorState({ ...defaultErrorState, card_num: true, message: 'Enter Card Number' });
                retV = false;
            }
            else if (!exp_date || !expRegEx.test(exp_date)) {
                setErrorState({ ...defaultErrorState, exp_date: true, message: 'Enter Card Expiry Date' });
                retV = false;
            }
            else if (!cvv || !cvvRegEx.test(cvv)) {
                setErrorState({ ...defaultErrorState, exp_date: true, message: 'Enter Card Expiry Date' });
                retV = false;
            }
        }

        //Adding additional check for Min and max amount
        if (amount && methodType !== 'EW') {
            const selectedChannel = ChannelType.find(channel => channel.value === paymentGatewayId);
            const { minLimit = 0, maxLimit = 1000 } = selectedChannel;
            if (Number(amount) < minLimit) {
                setErrorState({ ...defaultErrorState, amount: true, message: 'Deposit Amount Is Less Than The Minimum for The Channel' });
                retV = false;
            }
            else if (Number(amount) > maxLimit) {
                setErrorState({ ...defaultErrorState, amount: true, message: 'Deposit Amount Is More Than The Maximum for The Channel' });
                retV = false;
            }
        }

        //Additional check for Transaction id and image upload in case of manual 
        if (methodType === 'MD' || methodType === 'FUPI') {
            if (!transactionId) {
                setErrorState({ ...defaultErrorState, transactionId: true, message: 'Enter Transaction ID' });
                retV = false;
            }
            else if (!transactionImg) {
                setErrorState({ ...defaultErrorState, transactionImg: true, message: 'Upload Payment Reciept' });
                retV = false;
            }
        }

        return retV;
    }

    const submitDeposit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validation = validationCheck();

        if (validation) {
            const reqUserInfo = {
                user_firstname: userInfo.firstName,
                user_lastname: userInfo.lastName,
                user_email: userInfo.email,
                return_url: `${window.location.origin}${depositeUrl}`,
                device_type:2
            }

            //Adding one more field for applied coupon
            const { promoCode } = couponState;

            const {
                methodId,
                currencyCode,
                countryCode,
                amount,
                paymentGatewayId,
                methodType,
                EWData,
                transactionId,
                transactionImg
            } = depositState;

            const params = {
                method_id: methodId,
                currency_code: currencyCode,
                country_code: countryCode,
                amount,
                payment_gateway_id: paymentGatewayId,
                extra_data: { ...reqUserInfo }
            }

            if (promoCode) {
                params['couponCode'] = promoCode
            }

            if (methodType === 'EW') {
                params.extra_data = { ...reqUserInfo, ...EWData }
            }

            if (methodType === 'CRYP') {
                let selectedCurrency = {
                    'cryptocurrency' :currencyValue.currency
                }
                params.extra_data = { ...reqUserInfo, ...selectedCurrency }
            }

            //passing device token in API pqyload
            if (deviceToken && deviceToken.current) {
                params['device_token'] = deviceToken.current;
            }

            //adding event for CRM
            CRMDepositSubmitClick(brandId, methodId,amount,paymentGatewayId);

            if (methodType === 'MD' || methodType === 'FUPI') {
                const formData = new FormData();
                formData.append('amount', amount);
                formData.append('txn_id', transactionId);
                formData.append('txn_image', transactionImg);
                formData.append('payment_gateway_id', paymentGatewayId);
                formData.append('currency_code', currencyCode);
                formData.append('country_code', countryCode);
                formData.append('method_id', methodId);
                formData.append('user_firstname', userInfo.firstName);
                formData.append('user_lastname', userInfo.lastName);
                formData.append('user_email', userInfo.email);

                if (methodType === 'FUPI') {
                    formData.append('uuid', upiuuid);
                }
                if (methodType === 'MD') {
                    formData.append('uuid', bankuuid);
                }
                if (promoCode) {
                    formData.append('couponCode', promoCode);
                }

                //passing device token in API pqyload
                if (deviceToken && deviceToken.current) {
                    formData.append('device_token', deviceToken.current);
                }

                postManualDeposit(formData).then(res => {
                    if (res.data.success) {
                        const { data: { data: { transaction_sn } } } = res;
                        window.location.href = `/member/finance/deposit/${transaction_sn}`
                        //navigate(`/member/finance/deposit/${transaction_sn}`);
                    }
                }).catch(e => {
                    const { data } = e.response || {};
                    let message = 'Something Went Wrong, Please Try Again'
                    if (data && data.error) {
                        message = data.error;
                    }
                    Notify.error(message);
                });

            } else {
                postDeposit(params).then(res => {
                    if (res.data.success) {
                        const { data: { payment_url } } = res.data;
                        if (payment_url) {
                            window.location.href = payment_url
                        }

                        // try {
                        //     TriggerPxl(marketingSource, false, false, true, brandId);  // purchase is true after succesfull deposit

                        //     if (marketingSource && marketingSource.toLowerCase() === "channel383") {
                        //         TriggerPxlParticular(marketingSource, true, false, false, false, brandId)
                        //     }

                        // } catch (error) {
                        //     console.error('err', error);
                        // }
                    }
                }).catch(e => {
                    const { data } = e.response || {};
                    let message = 'Something Went Wrong, Please Try Again'
                    if (data && data.error) {
                        message = data.error;
                    }
                    Notify.error(message);
                });

            }

        }
    }

    const formatExpVal = (val) => {
        return val.replace(
            /[^0-9]/g, ''
        ).replace(
            /^([2-9])$/g, '0$1'
        ).replace(
            /^(1{1})([3-9]{1})$/g, '0$1/$2'
        ).replace(
            /^0{1,}/g, '0'
        ).replace(
            /^([0-1]{1}[0-9]{1})([0-9]{1,4}).*/g, '$1-$2'
        );
    }

    const cardExpiryHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        let EWData = depositState.EWData;
        setDepositState(prevS => ({ ...prevS, EWData: { ...EWData, exp_date: formatExpVal(val) } }));
        setErrorState(prevS => ({ ...prevS, exp_date: !val }))
    }

    const cardCVVHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        let EWData = depositState.EWData;
        const regExp = /^\d+$/;
        const preValue = EWData.cvv;
        val = val ? (regExp.test(val) ? val : preValue) : '';

        setDepositState(prevS => ({ ...prevS, EWData: { ...EWData, cvv: val } }));
        setErrorState(prevS => ({ ...prevS, cvv: !val }))
    }

    const cardNumberHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        const regExp = /^\d+$/;
        const EWData = depositState.EWData;
        const preValue = EWData.card_num;
        val = val ? (regExp.test(val) ? val : preValue) : '';

        setDepositState(prevS => ({ ...prevS, EWData: { ...EWData, card_num: val } }));
        setErrorState(prevS => ({ ...prevS, card_num: !val }))
    }

    const transactionIdHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        let val = e.target.value;
        const regExp = /[A-z0-9]$/
        const preV = depositState.transactionId;
        val = val ? (regExp.test(val) ? val : preV) : '';
        setDepositState(prevS => ({ ...prevS, transactionId: val }));
    }

    const resetHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        setDepositState(prevS => ({ ...defaultState, methodType: prevS.methodType, methodId: prevS.methodId, paymentGatewayId: prevS.paymentGatewayId }));
        setResetFile(true);
        setDefaultUUID(corporateBankDetails);
        setErrorState(defaultErrorState)
        setCouponState(couponCodeDefaultState);
        setCurrencyValue(defaultSelectedCurrency)
    }

    const copytoClipboard = (text) => {
        copyToClipboard(text)
            .then(() => Notify.success('Text copied '))
            .catch(() => console.log('error'));
    }

    const docUploadHandler = (f) => {
        setDepositState(prevS => ({ ...prevS, transactionImg: f }));
        setResetFile(false);

        if (!f) {
            setDepositState(prevS => ({ ...prevS, transactionId: '' }));
        }
    }

    const selectAccount = (uuid) => {
        setBankuuid(uuid);
    }

    const selectUPIUUID = (uuid) => {
        setUpiuuid(uuid);
        setScannerState(false)
    }

    const convertImageToText = async () => {
        const imageData = depositState.transactionImg;
        if (!imageData) return;
        await worker.load();
        await worker.loadLanguage("eng");
        await worker.initialize("eng");

        const { data } = await worker.recognize(imageData);
        const { text } = data;

        const getTrans = getTransId(text);
        setDepositState(prevS => ({ ...prevS, transactionId: getTrans }));
    };

    const qrCodeHandler = (upiid = '') => {
        setQRCodeId(upiid);
        setScannerState(true)
    }

    const closeScannerTooltip = () => {
        setScannerState(false)
    }

    const hideQAWrapper = (e) => {
        const qaWrapper = qrWrapperRef?.current;
        const target = e.target;
        if (qaWrapper && qaWrapper !== target) {
            setScannerState(false)
        }
    }

    // const cryptocurrencyHandler = (e) => {
    //     const _val = e.target.value;
    //     let currencyData = cryptocurrency.find(elm=>elm.currency === _val) || defaultSelectedCurrency;
    //     setCurrencyValue(currencyData);
    //     if(_val) {
    //         let supportedChannel = ChannelType.filter(elm=> (elm.crypto || []).find(curp=> curp.currency === _val));
    //         setCryptoChannelType(supportedChannel);
    //     }  
    // }

    const cryptocurrencyHandler = (selectedCurrency) => {
        // Find the selected currency data from cryptocurrency array
        let currencyData = cryptocurrency.find(elm => elm.currency === selectedCurrency) || defaultSelectedCurrency;
        setCurrencyValue(currencyData);  // Update the selected cryptocurrency data

        // If a valid currency is selected, update supported channels, else reset the channels
        if (selectedCurrency) {
            let supportedChannel = ChannelType.filter(elm => 
            (elm.crypto || []).some(curp => curp.currency === selectedCurrency)
            );
            setCryptoChannelType(supportedChannel);
        } else {
            setCryptoChannelType([]);  // Reset supported channels if no currency is selected
        }
    };


    const { methodId, amount, methodType, paymentGatewayId, EWData: { exp_date, cvv, card_num }, transactionId, transactionImg } = depositState;
    const isCouponIncomplete = showPromoCode && (couponState.blockSubmit);
    const enableSubmit = amount && methodType && paymentGatewayId && (methodType === 'EW' ? (exp_date && cvv && card_num) : true) && ((methodType === 'MD' || methodType === 'FUPI') ? (transactionId && transactionImg) : true) && !isCouponIncomplete;

    const show2PExtra = tier !== 'ELITE_TIER' && (brandId == 31 || brandId == 33) && is_ftd_done;
    const showCoupon = is_ftd_done;

    const { config: { show2PRedepositBonus } } = theme;

    // Extract methodType from both arrays
    const _depositMethodTypes = _depositeType.map(item => item.methodType);
    const depositMethodTypes = depositeType.map(item => item.methodType);

    // Find the intersection (methodTypes present in both arrays)
    const commonMethodTypes = _depositMethodTypes.filter(methodType => depositMethodTypes.includes(methodType));

    // Create a variable to store the result of the check
    const hasHighlightedChannel = ChannelType.some(
        (elm: { is_highlight: boolean }) => elm.is_highlight
    );

    const [dividerLoading, setLoading] = useState(true);
    useEffect(() => {
        // Simulate a loading process
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);


    return (
        <MainInfoWrapper>
            <SEOContents pageName="memberFinanceDeposit" />
            <Header>
                <FirstCol>
                    <UDBHeader title={ 
                        methodType === "FUPI" ? "Deposit@FastUpiDeposit" : 
                        methodType === "MD" ? "Deposit@FastBankDeposit" :
                         methodType === "UPI" ? "Deposit@RegularUpiDeposit" : 
                         methodType === "EW" ? "Deposit@NetBankingDeposit" : 
                         methodType === "CRYP" ? "Deposit@CryptoDeposit" : 
                         "Deposit@NetBankingDeposit"
                         } />
                </FirstCol>
                {showTimer === false ? <TimerDeposit created={created} /> : <></>}
            </Header>
            <form onSubmit={submitDeposit} style={{ position: 'relative' }}>
                {/* FakeInputWrapper is there to restrict autofill behavior */}
                <FakeInputWrapper>
                    <input type="text" name="fakeusername" />
                    <input type="password" name="fakeuserpassword" />
                </FakeInputWrapper>

                {!noDataMsg ? (
                    <BodyWrapper onClick={hideQAWrapper}>
                        {methodType === "FUPI" || methodType === "MD" || methodType === "NB" || methodType === "UPI" || methodType === "CRYP" ?
                            <DepositTopSecWrapper>
                                <DepositTopSecLeft className={brandId === 31 || brandId === 33 ? "customWidthLeft" : ""}>
                                    <ChooseDocument className={brandId === 31 || brandId === 33 ? "docMargin" : "docMargin"}>
                                        {depositeType.map(elm =>
                                            <CustomRadioDepositButton
                                                key={elm.methodType}
                                                checked={methodId === elm.value}
                                                topChoice={brandId === 32 || brandId === 34 || brandId === 40 ? false : elm.topChoice}
                                                id={elm.id}
                                                icon={elm.icon}
                                                theme={theme}
                                                name="methodType"
                                                label={<Intl langKey={elm.label} />}
                                                value={elm.value}
                                                onChange={(e) => paymentTypeHandler(e, elm.methodType)}
                                            />
                                        )}
                                    </ChooseDocument>

                                    {show2PExtra && show2PRedepositBonus && (commonMethodTypes.includes('FUPI') && commonMethodTypes.includes('MD')) ?
                                        <ExtraBonusWrp>
                                            <ExtraBonusSec>
                                                <h6>Get <span>{redeposit_percentage}% redeposit bonus</span></h6>
                                            </ExtraBonusSec>
                                        </ExtraBonusWrp>
                                     
                                    : show2PExtra && show2PRedepositBonus && (commonMethodTypes.includes('FUPI') || commonMethodTypes.includes('MD')) &&
                                        <ExtraBonusWrp className='moreSpace'>
                                            <ExtraBonusSecSingle>
                                                <h6>Get <span>{redeposit_percentage}% redeposit bonus</span></h6>
                                            </ExtraBonusSecSingle>
                                        </ExtraBonusWrp>
                                    }


                                </DepositTopSecLeft>
                                {/* {brandId === 31 || brandId === 32 ? null
                                : <DepositTopSecRight className={brandId === 31 || brandId === 33 ? "customWidthRight" : ""}>
                                    {methodType === "MD" || methodType === "UPI" || methodType === "NB" ? <FastBankDepositSteps /> : methodType === "FUPI" ? <FastUpiDepositStepsIcon /> : <></>}
                                </DepositTopSecRight>
                                } */}
                                
                            </DepositTopSecWrapper> : <></>
                        }

                        <MainSection>

                            {ChannelType.length && (methodType === 'MD' || methodType === 'FUPI') ? (
                                <ManualDepositWrapp>

                                    <ManualDespositSec>
                                        {methodType === 'MD' && (
                                            <>
                                                {showMDError && (!corporateBankDetails.bank_details || corporateBankDetails.bank_details.length <= 0) ? (<NoChannelMsg className='noCh'><Intl langKey="Deplost@NoChannel" /></NoChannelMsg>) : (
                                                    <ManualDepositBankDetailsWrapper>
                                                        <div className={corporateBankDetails.bank_details?.length >= 2 ? "manualDepositBankDetailsWrap" : "manualDepositBankDetailsWrap2"}>
                                                            {/* {(brandId === 31 || brandId === 32) && (<TextInside><Intl langKey='MANUALDEPOSIT@YoloBankNew' /></TextInside>)} */}
                                                            <TextInside><Intl langKey='MANUALDEPOSIT@YoloBankNew' /></TextInside>
                                                            {
                                                                (corporateBankDetails.bank_details || []).map((elm, i) => (<DepositBankDetailComponent key={i} option={i} data={elm} uuid={bankuuid} copyHandler={copytoClipboard} onSelect={selectAccount} theme={theme} />))
                                                            }

                                                            <GatewayProviderWrap>
                                                                <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.fastBankSupportsImg}`} alt="fastBankSupports" />
                                                            </GatewayProviderWrap>
                                                        </div>

                                                        <>
                                                            <FormAndUploadWrap className="fastBankFormWrap">
                                                                <SubTittleWrap className="docUploadHead"><Intl langKey='MANUALDEPOSIT@YoloBankuploadRecipt' /><p><Intl langKey='MANUALDEP@DocSubmit' /></p></SubTittleWrap>
                                                                <UploadDiv>
                                                                    <FileUpload name="fsdoc" isReset={resetFile} label={'Upload file (Clear image)'} isReq={true} onUpload={docUploadHandler} maxSizeMB={5} isDeposit={true} />
                                                                </UploadDiv>

                                                                <SubTittleWrap style={{ justifyContent: "flex-start", width: "100%" }}>
                                                                    <Intl langKey='MANUALDEPOSIT@YoloBankVerifyPayment' />
                                                                </SubTittleWrap>
                                                                <AmountWrapper className={errorState.amount ? 'md_inputWrp error staticEnhance' : 'md_inputWrp staticEnhance'}>
                                                                    <BankInputField placeholder="Enter Deposit Amount" value={amount} onChange={depositAmountHandler} name="amount" req />
                                                                    <ErrorMsg>{errorState.amount && errorState.message}</ErrorMsg>
                                                                </AmountWrapper>


                                                                <DepositInfoWrapper>
                                                                    <DepositInfo>
                                                                        (<Intl langKey="MANUALDEPOSIT@MinDeposit" />:
                                                                        <b>₹{ChannelType[0].minLimit}</b> | <Intl langKey="MANUALDEPOSIT@MaxDeposit" />:
                                                                        <b>₹{ChannelType[0].maxLimit}</b>)
                                                                    </DepositInfo>
                                                                </DepositInfoWrapper>
                                                                <AmountWrapper className={errorState.transactionId ? 'md_inputWrp error staticEnhance' : 'md_inputWrp staticEnhance'}>
                                                                    <BankInputField placeholder="UTR Number" value={transactionId} onChange={transactionIdHandler} name="transactionId" req />
                                                                    <ErrorMsg>{errorState.transactionId && errorState.message}</ErrorMsg>
                                                                </AmountWrapper>

                                                                {/* for bonus */}
                                                                {showCoupon && <>
                                                                <CouponCaption htmlFor='promo' >
                                                                    <div className="custom-checkbox">
                                                                        <input  type="checkbox" name="promo" id="promo" onClick={togglePromoCode} />
                                                                        <span></span>
                                                                        <Intl langKey="depositbonuscode" />
                                                                    </div>
                                                                    {/* <input  type="checkbox" name="promo" id="promo" onClick={togglePromoCode} />
                                                                    <Intl langKey="depositbonuscode" /> */}
                                                                </CouponCaption>
                                                                    {showPromoCode && (
                                                                        <AmountWrapper className={couponState.isInvalid ? 'md_inputWrp error staticEnhance' : 'md_inputWrp staticEnhance'}>
                                                                            <PhoneNumberAndOtpWrap className='reqBRed'>
                                                                                <BankInputField
                                                                                    placeholder="Enter Promo Code"
                                                                                    value={couponState.promoCode}
                                                                                    onChange={CouponCodeHandler}
                                                                                    name="couponCode"
                                                                                    disabled={couponState.showEdit}
                                                                                />
                                                                                {!couponState.showEdit && (<OtpButton
                                                                                    disabled={!couponState.isActive}
                                                                                    isActive={couponState.isActive}
                                                                                    className="verifyBtn"
                                                                                    id="couponApplyBtn"
                                                                                    type="button"
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        validateCouponCode()
                                                                                    }}
                                                                                >
                                                                                    <TickIcon />
                                                                                </OtpButton>)}
                                                                                {couponState.showEdit && (<OtpButton
                                                                                    isActive={true}
                                                                                    className="resetBtn"
                                                                                    id="couponRemoveBtn"
                                                                                    type="button"
                                                                                    onClick={(event) => {
                                                                                        event.stopPropagation();
                                                                                        ResetCouponCode()
                                                                                    }}
                                                                                >
                                                                                    <CrossIcon />
                                                                                </OtpButton>)}
                                                                            </PhoneNumberAndOtpWrap>
                                                                            <ErrorMsg>{couponState.isInvalid && couponState.showPromoMsg}</ErrorMsg>
                                                                            {!couponState.isInvalid && couponState.showPromoMsg && <PromoSuccessMsg>{couponState.showPromoMsg}</PromoSuccessMsg>}
                                                                        </AmountWrapper>)}
                                                                </>}

                                                                <ButtonWrapper style={{ width: "100%" }}>
                                                                    <SubmitBtn disabled={!enableSubmit} type='submit'><Intl langKey="DASHBOARD@submit" /></SubmitBtn>
                                                                    <ResetBtn onClick={resetHandler}><Intl langKey="DASHBOARD@reset" /></ResetBtn>
                                                                </ButtonWrapper>
                                                            </FormAndUploadWrap>
                                                        </>
                                                    </ManualDepositBankDetailsWrapper>
                                                )}
                                            </>
                                        )}

                                        {methodType === 'FUPI' && (
                                            <> {showMDError && (!corporateBankDetails.upi_details || corporateBankDetails.upi_details.length <= 0) ? (<NoChannelMsg className='noCh'><Intl langKey="Deplost@NoChannel" /></NoChannelMsg>) : (
                                                <>
                                                    <ManualDepositUPILeftWrap className={corporateBankDetails.upi_details?.length >= 2 ? "manualUpi2" : "manualUpi1"}>
                                                    {/* { (brandId === 31 || brandId === 32) && (<TextInside><Intl langKey='MANUALDEPOSIT@UPINEW' /></TextInside>)} */}
                                                    <TextInside><Intl langKey='MANUALDEPOSIT@UPINEW' /></TextInside>
                                                        {
                                                            (corporateBankDetails.upi_details || []).map((elm, i) => <DepositBankUPIComponent key={i} option={i} data={elm} uuid={upiuuid} onSelect={selectUPIUUID} channel={ChannelType[0]} openQRCodeHandler={qrCodeHandler} copyHandler={copytoClipboard} theme={theme} />)
                                                        }
                                                        <GatewayProviderWrap>
                                                            <LazyLoadImage src={`${config.imageV3Host}/static/cashsite/${theme.image.fastUpiGateways}`} alt="fastUpiGateways" />
                                                        </GatewayProviderWrap>
                                                    </ManualDepositUPILeftWrap>

                                                    <FormAndUploadWrap style={{ width: "45%" }}>
                                                        <SubTittleWrap className="docUploadHead"><Intl langKey='MANUALDEPOSIT@YoloBankuploadRecipt' /><p><Intl langKey='MANUALDEP@DocSubmit' /></p></SubTittleWrap>
                                                        <UploadDiv>
                                                            <FileUpload name="fsdoc" isReset={resetFile} label={'Upload file (Clear image)'} isReq={true} onUpload={docUploadHandler} maxSizeMB={5} isDeposit={true} />
                                                        </UploadDiv>

                                                        <SubTittleWrap style={{ justifyContent: "flex-start", width: "100%" }}>
                                                            <Intl langKey='MANUALDEPOSIT@YoloBankVerifyPayment' />
                                                        </SubTittleWrap>

                                                        <AmountWrapper className={errorState.amount ? 'md_inputWrp error staticEnhance' : 'md_inputWrp staticEnhance'}>
                                                            <BankInputField placeholder="Enter Deposit Amount" value={amount} onChange={depositAmountHandler} name="amount" req />
                                                            <ErrorMsg>{errorState.amount && errorState.message}</ErrorMsg>
                                                        </AmountWrapper>

                                                        <DepositInfoWrapper>
                                                            <DepositInfo>
                                                                (<Intl langKey="MANUALDEPOSIT@MinDeposit" />:
                                                                <b>₹{ChannelType[0].minLimit}</b> | <Intl langKey="MANUALDEPOSIT@MaxDeposit" />:
                                                                <b>₹{ChannelType[0].maxLimit}</b>)
                                                            </DepositInfo>
                                                        </DepositInfoWrapper>

                                                        <AmountWrapper className={errorState.transactionId ? 'md_inputWrp error staticEnhance' : 'md_inputWrp staticEnhance'}>
                                                            <BankInputField placeholder="UTR Number" value={transactionId} onChange={transactionIdHandler} name="transactionId" req />
                                                            <ErrorMsg>{errorState.transactionId && errorState.message}</ErrorMsg>
                                                        </AmountWrapper>

                                                        {/* for bonus */}
                                                        {showCoupon && <>
                                                            <CouponCaption htmlFor='promo' >
                                                                <div className="custom-checkbox">
                                                                    <input  type="checkbox" name="promo" id="promo" onClick={togglePromoCode} />
                                                                    <span></span>
                                                                    <Intl langKey="depositbonuscode" />
                                                                </div>
                                                                
                                                                {/* <input  type="checkbox" name="promo" id="promo" onClick={togglePromoCode} />
                                                                <Intl langKey="depositbonuscode" /> */}
                                                            </CouponCaption>
                                                            {showPromoCode && (
                                                                <AmountWrapper className={couponState.isInvalid ? 'md_inputWrp error staticEnhance' : 'md_inputWrp staticEnhance'}>
                                                                    <PhoneNumberAndOtpWrap className='reqBRed'>
                                                                        <BankInputField
                                                                            placeholder="Enter Promo Code"
                                                                            value={couponState.promoCode}
                                                                            onChange={CouponCodeHandler}
                                                                            name="couponCode"
                                                                            disabled={couponState.showEdit}
                                                                        />
                                                                        {!couponState.showEdit && (<OtpButton
                                                                            disabled={!couponState.isActive}
                                                                            isActive={couponState.isActive}
                                                                            className="verifyBtn"
                                                                            id="couponApplyBtn"
                                                                            type="button"
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                validateCouponCode()
                                                                            }}
                                                                        >
                                                                            <TickIcon />
                                                                        </OtpButton>)}
                                                                        {couponState.showEdit && (<OtpButton
                                                                            isActive={true}
                                                                            className="resetBtn"
                                                                            id="couponRemoveBtn"
                                                                            type="button"
                                                                            onClick={(event) => {
                                                                                event.stopPropagation();
                                                                                ResetCouponCode()
                                                                            }}
                                                                        >
                                                                            <CrossIcon />
                                                                        </OtpButton>)}
                                                                    </PhoneNumberAndOtpWrap>
                                                                    <ErrorMsg>{couponState.isInvalid && couponState.showPromoMsg}</ErrorMsg>
                                                                    {!couponState.isInvalid && couponState.showPromoMsg && <PromoSuccessMsg>{couponState.showPromoMsg}</PromoSuccessMsg>}
                                                                </AmountWrapper>)}
                                                        </>}

                                                        <ButtonWrapper>
                                                            <SubmitBtn disabled={!enableSubmit} type='submit'><Intl langKey="DASHBOARD@submit" /></SubmitBtn>
                                                            <ResetBtn onClick={resetHandler}><Intl langKey="DASHBOARD@reset" /></ResetBtn>
                                                        </ButtonWrapper>
                                                    </FormAndUploadWrap>
                                                </>
                                            )}</>
                                        )}
                                    </ManualDespositSec>
                                </ManualDepositWrapp>
                            ) : (
                                <ChannelSection>
                                    <LeftSection>
                                        {methodType === 'EW' ? (
                                            <>
                                                <ProviderWrapp>
                                                    {paymentProviderType.map(elm => <PaymentProviderRadioButton checked={paymentGatewayId === elm.value} key={elm.value} name="methodId" icon={elm.icon} label={elm.label} value={elm.value} onChange={channelSelecHandler} />)}
                                                </ProviderWrapp>
                                                <AmountWrapper className={errorState.amount ? 'error' : ''}> <BankInputField placeholder="Enter Deposit Amount" onChange={depositAmountHandler} value={amount} name="amount" req /> <ErrorMsg>{errorState.amount && errorState.message}</ErrorMsg> </AmountWrapper>
                                                <CardDetails>
                                                    <SubTittle><Intl langKey="DASHBOARD@CARDDETAILS" /></SubTittle>
                                                    <SubWrap>
                                                        <CardNumber className={errorState.card_num ? 'error' : ''}><BankInputField value={card_num} placeholder="16 digit Number" req onChange={cardNumberHandler} /></CardNumber>
                                                        <CardExpiry className={errorState.exp_date ? 'error' : ''}><BankInputField placeholder="MM-YYYY" value={exp_date} name="expiry" onChange={cardExpiryHandler} req /></CardExpiry>
                                                        <CvvInfo className={errorState.cvv ? 'error' : ''}><BankInputField type="password" name="cvv" value={cvv} placeholder="CVV" onChange={cardCVVHandler} req /></CvvInfo>
                                                    </SubWrap>
                                                </CardDetails>
                                            </>
                                        ) :
                                            (methodType === 'UPI' || methodType === 'NB' ? <RegularDepositLeftWrap>
                                                <div className="regularDepositLeftSec">
                                                    <SubTittle2> <Intl langKey="DASHBOARD@CHOOSECHANNEL" /></SubTittle2>
                                                    <AmountWrapper className={errorState.amount ? 'error staticEnhance' : 'staticEnhance'} style={{ width: "100%", marginBottom: "10px" }}>
                                                        <BankInputField placeholder="Enter Deposit Amount" value={amount} onChange={depositAmountHandler} name="amount" req />
                                                        <ErrorMsg>{errorState.amount && errorState.message}</ErrorMsg>
                                                    </AmountWrapper>

                                                    <ChooseChannel>
                                                        {ChannelType.filter(elm => elm.is_highlight).sort((a, b) => (a.is_highlight === b.is_highlight) ? 0 : a.is_highlight ? -1 : 1).map((elm, i) => <CustomRadioButton key={i} name="channel" is_highlight={elm.is_highlight} bonus_amount={redeposit_percentage}checked={paymentGatewayId === elm.value} label={<ChannelLabel data={elm} />} value={elm.value} onChange={channelSelecHandler} className={elm.is_highlight && redeposit_percentage ? paymentGatewayId === elm.value ? "specialChannelSelected" : "specialChannel" : ""} activeChannel={paymentGatewayId === elm.value} />)}

                                                            {/* Display message if the selected channel is not is_highlight: true */}
                                                            {ChannelType.some((elm: { value: string; is_highlight: any; }) => (hasHighlightedChannel) && elm.value === paymentGatewayId && !elm.is_highlight) ? (
                                                                <ChannelMissoutMsg>
                                                                    {brandId == 31 || brandId == 32 ? <SpecialChannelWarning /> : <SpecialChannelWarningBaazi />}
                                                                    <p>
                                                                        <Intl langKey="NoteSpecialChannelWarning" />
                                                                        <strong> {redeposit_percentage}% <Intl langKey="NoteSpecialChannelWarningText" /> </strong>
                                                                        <Intl langKey="NoteSpecialChannelWarningEnd" />
                                                                    </p>
                                                                    {brandId == 31 || brandId == 32 ? <SpecialChannelWarning /> : <SpecialChannelWarningBaazi />}
                                                                </ChannelMissoutMsg>
                                                            ) : ChannelType.some((elm: any) => elm.is_highlight && redeposit_percentage) ? <Divider></Divider> : <></>
                                                            }



                                                        {ChannelType.filter(elm => !elm.is_highlight).sort((a, b) => (a.is_highlight === b.is_highlight) ? 0 : a.is_highlight ? -1 : 1).map((elm, i) => <CustomRadioButton key={i} name="channel" is_highlight={elm.is_highlight} bonus_amount={redeposit_percentage}checked={paymentGatewayId === elm.value} label={<ChannelLabel data={elm} />} value={elm.value} onChange={channelSelecHandler} className={elm.is_highlight && redeposit_percentage ? paymentGatewayId === elm.value ? "specialChannelSelected" : "specialChannel" : ""} activeChannel={paymentGatewayId === elm.value} />)}

                                                        

                                                        {/* Display message if the selected channel is not is_highlight: true */}
                                                            {ChannelType.some((elm: { value: string; is_highlight: any; }) => (hasHighlightedChannel) && elm.value === paymentGatewayId && !elm.is_highlight) ? (
                                                                <ChannelMissoutMsg>
                                                                    {brandId == 31 || brandId == 32 ? <SpecialChannelWarning /> : <SpecialChannelWarningBaazi />}
                                                                    <p>
                                                                        <Intl langKey="NoteSpecialChannelWarning" />
                                                                        <strong> {redeposit_percentage}% <Intl langKey="NoteSpecialChannelWarningText" /> </strong>
                                                                        <Intl langKey="NoteSpecialChannelWarningEnd" />
                                                                    </p>
                                                                    {brandId == 31 || brandId == 32 ? <SpecialChannelWarning /> : <SpecialChannelWarningBaazi />}
                                                                </ChannelMissoutMsg>
                                                            ) : <></>
                                                            }
                                                        

                                                        {errorState.paymentGatewayId && <span>{errorState.message}</span>}
                                                    </ChooseChannel>

                                                    {/* { brandId === 31 || brandId === 32 ? <DividerLineHorizontal></DividerLineHorizontal> : null } */}
                                                    <DividerLineHorizontal></DividerLineHorizontal>

                                                    {showChannelMsg && (<SelectionAlertMsgWrp>
                                                        <div className='alertMsg'>
                                                            <span className='exclamationIcon'>
                                                                <img src={`${config.imageV3Host}/static/cashsite/${theme.image.alertMsgExclamationIcon}`}/>
                                                            </span> 
                                                            {/* <span className='exclamation'>!</span> */}
                                                            <Intl langKey="WithdrawalChannelMsg" />
                                                        </div>
                                                    </SelectionAlertMsgWrp>)}
                                                    {/* for bonus */}
                                                    {showCoupon && <>
                                                        <CouponCaption htmlFor='promo' className='noML' >
                                                            <div className="custom-checkbox marginTop">
                                                                <input  type="checkbox" name="promo" id="promo" onClick={togglePromoCode} />
                                                                <span></span>
                                                                <Intl langKey="depositbonuscode" />
                                                            </div>
                                                            {/* <input  type="checkbox" name="promo" id="promo" onClick={togglePromoCode} />
                                                            <Intl langKey="depositbonuscode" /> */}
                                                        </CouponCaption>
                                                        {showPromoCode && (
                                                            <AmountWrapper className={couponState.isInvalid ? 'md_inputWrp error staticEnhance' : 'md_inputWrp staticEnhance'}>
                                                                <PhoneNumberAndOtpWrap className='reqBRed'>
                                                                    <BankInputField
                                                                        placeholder="Enter Promo Code"
                                                                        value={couponState.promoCode}
                                                                        onChange={CouponCodeHandler}
                                                                        name="couponCode"
                                                                        disabled={couponState.showEdit}
                                                                    />
                                                                    {!couponState.showEdit && (<OtpButton
                                                                        disabled={!couponState.isActive}
                                                                        isActive={couponState.isActive}
                                                                        className="verifyBtn"
                                                                        id="couponApplyBtn"
                                                                        type="button"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            validateCouponCode()
                                                                        }}
                                                                    >
                                                                        <TickIcon />
                                                                    </OtpButton>)}
                                                                    {couponState.showEdit && (<OtpButton
                                                                        isActive={true}
                                                                        className="resetBtn"
                                                                        id="couponRemoveBtn"
                                                                        type="button"
                                                                        onClick={(event) => {
                                                                            event.stopPropagation();
                                                                            ResetCouponCode()
                                                                        }}
                                                                    >
                                                                        <CrossIcon />
                                                                    </OtpButton>)}
                                                                </PhoneNumberAndOtpWrap>
                                                                <ErrorMsg>{couponState.isInvalid && couponState.showPromoMsg}</ErrorMsg>
                                                                {!couponState.isInvalid && couponState.showPromoMsg && <PromoSuccessMsg>{couponState.showPromoMsg}</PromoSuccessMsg>}
                                                            </AmountWrapper>)}
                                                    </>}
                                                </div>


                                                <ButtonWrapper style={{ width: "90%", marginTop: "1rem" }}>
                                                    <SubmitBtn disabled={!enableSubmit} type='submit'><Intl langKey="DASHBOARD@submit" /></SubmitBtn>
                                                    <ResetBtn onClick={resetHandler}><Intl langKey="DASHBOARD@reset" /></ResetBtn>
                                                </ButtonWrapper>

                                            </RegularDepositLeftWrap> : null)
                                        }

                                        {
                                            /* A lot of Condition is already exist, so seperating Crypto for better understanding*/
                                            (ChannelType.length && methodType === 'CRYP') ? (
                                                <RegularDepositLeftWrap>
                                                    <div className="regularDepositLeftSec cryptoClass">
                                                    <CryptoCurrencyDropDown  
                                                        data = {cryptocurrency} 
                                                        value={currencyValue.currency}
                                                        placeholder= "Select Crypto Currency"
                                                        onChange={cryptocurrencyHandler}
                                                    />
                                                    <AmountWrapper className={errorState.amount ? 'error staticEnhance' : 'staticEnhance'} style={{ width: "100%" }}>
                                                        <BankInputField placeholder="Enter Deposit Amount (INR)" value={amount} onChange={depositAmountHandler} name="amount" req />
                                                        <ErrorMsg>{errorState.amount && errorState.message}</ErrorMsg>
                                                    </AmountWrapper>

                                                    {/*As per the design Exchange Rate is showing inside the select box so hiding this code*/}
                                                   {/* {currencyValue.currency && <ExchangeRateWrapper>
                                                        <P><span>{currencyValue.currency}</span>
                                                        <span className='rtl'>{`${amount ?  (Number(amount)/Number(currencyValue.exchangeRate)).toFixed(2) : '0.00'}`}</span> </P>
                                                        <P><span>Exchange Rate</span><span className='rtl'>1 {currencyValue.currency} = {Number(currencyValue.exchangeRate).toFixed(2)} INR</span></P>
                                                    </ExchangeRateWrapper>} */}

                                                    
                                                    {currencyValue.currency && <ChooseChannel>
                                                        <DividerLineHorizontal style={{ margin: "8px 0 5px 0px" }}></DividerLineHorizontal>
                                                        {cryptoChannelType.map((elm, i) => <CustomRadioButton key={elm.value} name="channel" checked={paymentGatewayId === elm.value} label={<ChannelLabel data={elm} />} value={elm.value} onChange={channelSelecHandler} />)}
                                                        {errorState.paymentGatewayId && <span>{errorState.message}</span>}                                                        
                                                    </ChooseChannel>
                                                    }

                                                    <DividerLineHorizontal></DividerLineHorizontal>
                                                    <ButtonWrapper style={{ margin: "15px 0 5px 0px"}}>
                                                        <SubmitBtn disabled={!enableSubmit} type='submit'><Intl langKey="DASHBOARD@submit" /></SubmitBtn>
                                                        <ResetBtn onClick={resetHandler}><Intl langKey="DASHBOARD@reset" /></ResetBtn>
                                                    </ButtonWrapper>

                                                    </div>
                                                </RegularDepositLeftWrap>
                                            ) : null
                                        }

                                    </LeftSection>

                                    {!dividerLoading && <DividerLine></DividerLine>}

                                    <RightSection>
                                        {methodType === 'UPI' ?
                                            <LazyLoadImage src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.depositFastUpiBanner}`} alt="deposit FastUpi" /> : methodType === 'NB' ? <LazyLoadImage src={`${config.imageV3NonOptHost}/static/cashsite/${theme.image.depositFastBankBanner}`} alt="depositFastBankBanner" /> : null
                                        }
                                    </RightSection>

                                    
                                </ChannelSection>
                            )}

                            {/* Note Section */}
                            {methodType === "FUPI" || methodType === "NB" || methodType === "UPI" ?
                                <ManualDepositTerms>
                                    <TextTerms>
                                        <i className='req'>*</i>
                                        <Intl langKey='MANUALDEPOSIT@PleaseVerify' />
                                    </TextTerms>
                                    <TextTerms>
                                        <i className='req'>*</i>
                                        <Intl langKey='MANUALDEPOSIT@IfYourDeposit' />
                                    </TextTerms>
                                </ManualDepositTerms>
                                : methodType !=="CRYP" ? <ManualDepositTerms>
                                    <TextTerms>
                                        <i className='req'>*</i>
                                        <Intl langKey='MANUALDEPOSIT@ThedailyDepositBonusIsApplicable' />
                                    </TextTerms>
                                    <TextTerms>
                                        <i className='req'>*</i>
                                        <Intl langKey='MANUALDEPOSIT@PleaseVerify' />
                                    </TextTerms>
                                    <TextTerms>
                                        <i className='req'>*</i>
                                        <Intl langKey='MANUALDEPOSIT@IfYourDeposit' />
                                    </TextTerms>
                                </ManualDepositTerms> : <></>
                            }

                        </MainSection>

                    </BodyWrapper>
                ) : <NoChannelMsg><Intl langKey="DASHBOARD@noChanners" /> </NoChannelMsg>
                }

                {scannerState && <FastUpiScanner ref={qrWrapperRef} id={`upi://pay?pa=${qrCodeId}&pn=${qrCodeId}&cu=INR`} scannerDisplay={scannerState}>
                    <QRcodeWrapper>
                        <p onClick={closeScannerTooltip}><DepositCloseIcon /></p>
                        <QRCodeText><Intl langKey="Deposit@scanQRCode" /></QRCodeText>
                        <QRCodeBox><QRCode value={`upi://pay?pa=${qrCodeId}&pn=${qrCodeId}&cu=INR`} size={150} /></QRCodeBox>
                    </QRcodeWrapper>
                </FastUpiScanner>
                }
            </form>
        </MainInfoWrapper>
    )
}

export default withTheme(withAppContext(Deposit));

//styled Component

const BodyWrapper = styled(DepositBodyWrapper)`
    /* background:  ${theme('palette.udbDepositBodyWrapperBg')}; */
    background: transparent;
    /* padding: ${theme('palette.udbDepositBodyWrapperPadding')}; */
    padding: 0;
`;

const ButtonWrapper = styled(DepoButtonWrapper)`
    width: 100%;
    justify-content: ${theme('palette.depoBtnWrprrJustifyContent')};
`;

const ResetBtn = styled(DepoResetBtn)`
    background:  ${theme('palette.depoResetBtnBg')};
    border-radius: ${theme('palette.depoResetBtnBordrRadus')};
    color: ${theme('palette.depoResetBtnColor')};

    :hover {
        /* color: #232eb6; */
        color: ${theme('palette.depoResetBtnHoverColor')};
    }
`;

const SubmitBtn = styled(DepoSubmitBtn)`
    /* width: 70%; */
    width: ${theme('palette.depoSubmitBtnWidth')};
    /* border-radius: 8.8px; */
    border-radius: ${theme('palette.depoSubmitBtnBordrRadus')};
    /* background: linear-gradient(180deg, #0C8512 0%, #4FC247 100%); */
    background: ${theme('palette.depoSubmitBtnActiveBg')};
    justify-content: center;

    :disabled {
        /* background: linear-gradient(180deg, rgba(12, 133, 18, 0.60) 0%, rgba(79, 194, 71, 0.60) 100%); */
        /* background: ${theme('palette.depoSubmitBtnDisabledBg')}; */
        background: linear-gradient(180deg, rgba(12, 133, 18, 0.60) 0%, rgba(79, 194, 71, 0.60) 100%);
    }
    :hover {
        background: linear-gradient(180deg, rgba(12, 133, 18, 0.60) 0%, rgba(79, 194, 71, 0.60) 100%) !important;
          /* background: linear-gradient(180deg, rgba(12, 133, 18, 0.60) 0%, rgba(79, 194, 71, 0.60) 100%); */
    }
`;

const ExchangeRateWrapper = styled.div`
    width: 100%;
    height: 66px;
    flex-shrink: 0;
    border-radius: 3.185px;
    border: 1px solid rgba(178, 189, 255, 0.30);
    background: rgba(103, 120, 227, 0.20);
    backdrop-filter: blur(5px);
    margin: 1px 0 9px;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const P = styled.p`
    display: flex;
    color:#BDBDBD;
    font-size: 14px;
    padding: 3px 10px;
    margin: 0;
    span {
        width: 50%;
        display: flex;
        flex: 1;
    }
    span.rtl{
        justify-content: end;
    }
`

const CouponCaption = styled.label`
    display: flex;
    gap: 5px;
    color: #fff;
    font-size: .81em;
    justify-content: center;
    align-items: center;
    font-style: oblique;
    /* width: 97%; */
    width: ${theme('palette.amountWrapperInpurWrapWidth')};
    flex-direction: row;
    justify-content: flex-start;
    /* margin-left: 8px; */
    margin-left: ${theme('palette.amountWrapperInpurWrapMargin')};
    cursor: pointer;
    text-decoration: underline;
    & svg {
        width: 20px;
        height: 20px;
    }
    input {
        margin: 0;
    }

    .custom-checkbox {
        display: flex;
        align-items: center;
        gap: 10px;
    }

    .marginTop {
        margin-top: 15px;
    }

    .custom-checkbox input {
        display: none;
    }

    .custom-checkbox span {
        width: 20px;
        height: 20px;
        display: inline-block;
        background: linear-gradient(180deg, rgba(217, 217, 217, 0.19) 0%, rgba(115, 115, 115, 0.19) 100%);
        border: 1.094px solid #ffffff9e;
        border-radius: 6.563px;
        position: relative;
        cursor: pointer;
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.3);
    }

    .custom-checkbox input:checked + span {
        background-color: #555;
        border-color: #fff;
        background: url(${config.imageV3Host}/static/cashsite/brand/6/icon/custom-checkbox-check.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .custom-checkbox span:hover {
        background-color: #333;
    }
`;

const PromoSuccessMsg = styled.div`
    color:#0EAD69;
    font-size: .75em;
    font-weight: 600;
    width: 100%;
    text-align: left;
`;

const SelectionAlertMsgWrp = styled.div`
    display: block;
    position: relative;
    height:27px;
    margin-bottom: 2px;
    .alertMsg {
        /* background: linear-gradient(90deg, rgba(233,193,52,1) 0%, rgba(233,160,45,1) 9%, rgba(195,134,42,1) 100%);   */
        background: ${theme('palette.UDBSelectionAlertMsgWrpBg')};
        position: absolute;
        display: flex;
        left: -10px;
        right: -10px;
        height: 100%;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 12px;
        font-weight: 700;
    }   
    .exclamation{
        width: 20px;
        height: 20px;
        background: #fff;
        border-radius: 50%;
        margin-right: 6px;
        display: flex;
        justify-content: center;
        color: #E9A02D;
        font-size: 15px;
        font-weight: 700;
        align-items: center;
    }

    .exclamationIcon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 3px;

        img {
            max-width: 100%;
        }
    }
`;

const MainSection = styled.div`
    border: ${theme('palette.UDBMainSectionBorder')};
    box-shadow: ${theme('palette.UDBMainSectionBoxShadow')};
    border-radius: 3px;
    background: ${theme('palette.UDBMainSectionBg')};
    width: 100%;
`;

const AccountHolderWrp = styled.div`
    display: flex;
    height: 75%;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 20px 0px;
    cursor: pointer;
    gap: 5px;
    margin-bottom: 5px;
    flex-direction: column;
`

const ManualDepositTerms = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border-radius: ${theme('palette.ManualDepositTermsBorderRadius')};
    border: ${theme('palette.ManualDepositTermsBorder')};
    margin: ${theme('palette.ManualDepositTermsMargin')};
    margin-top: ${theme('palette.ManualDepositTermsMarginTop')};
    background: ${theme('palette.ManualDepositWrapBg')};
`;

const TextTerms = styled.p`
    display: flex;
    gap: 6px;
    color: ${theme('palette.DepositTextColorPrimary')};;
    text-align: left;
    font-size: 10px;

    i.req { 
        color: ${theme('palette.ErrorMsgColor')};; 
        padding-left:3px
    }
`;

const SubWrap = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap:10px;
`;
const CvvInfo = styled.div`
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.error {
        label { 
            border: 1px solid ${theme('palette.ErrorMsgColor')};;
            background: ${theme('palette.DepositLabelTextColor')};
        }
    } 
`;
const CardExpiry = styled(CvvInfo)` 
    width: 20%;  
    &.error {
        label { 
            border: 1px solid ${theme('palette.ErrorMsgColor')};;
            background: ${theme('palette.DepositLabelTextColor')};
        }
    } 
    
`;
const CardNumber = styled(CvvInfo)` 
    width: 60%;  
    &.error {
        label { 
            border: 1px solid ${theme('palette.ErrorMsgColor')};;
            background: ${theme('palette.DepositLabelTextColor')};
        }
    } 
`;
const AmountWrapper = styled(CvvInfo)` 
    width: 60%; 
    flex-direction: column;
    align-items: flex-start;
    &.md_inputWrp {
        /* margin-left: 8px;
        width: 97%; */
        margin-left: ${theme('palette.amountWrapperInpurWrapMargin')};
        width: ${theme('palette.amountWrapperInpurWrapWidth')};
    }
    .reqBRed label {
        border-radius: 5px 0px 0 5px!important;
    }
    &.error {
        label { 
            border: 1px solid ${theme('palette.ErrorMsgColor')};
            background: ${theme('palette.DepositLabelTextColor')};
        }
        .verifyBtn {
            border: 1px solid ${theme('palette.ErrorMsgColor')};
            border-left: none;
        }
    }
    .reqBRed label {
        border-radius: 5px 0px 0 5px!important;
        border-right:none;
    }

    .verifyBtn {
        width:69px;
        padding-left:5px!important;
        padding-right:5px!important;
        flex-shrink: 0;
        background:${theme("palette.secondaryColor")}
    }
    button[disabled].verifyBtn {
        color: #564f4f;
    }
    
    .resetBtn {
        width:69px;
        padding-left:5px!important;
        padding-right:5px!important;
        flex-shrink: 0;
        background:${theme("palette.selectedEventBg")}  
    }  
`;
const CardDetails = styled.div`
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;
const ProviderWrapp = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding-bottom: 1em;
`;
const ChannelSection = styled(Upload)`
  align-items: center;
  border:none;
  /* max-height: 350px; */
`;
const LeftSection = styled.div`
    padding: 15px 15px;
    width: 50%;
`;

const Divider = styled(DepoDivider)`
    margin-bottom: 1rem;
    width: 100%;
    height: 2px;
    background: radial-gradient(rgba(255, 255, 255, 0.96) 0%, rgba(0, 0, 0, 0) 70%);
`;

const DividerLine = styled.div`
    width: 0.994px;
    /* height: 430px; */
    height: 40%;
    background: linear-gradient(transparent, rgba(255, 255, 255, 0.96), transparent);
    position: absolute;
    right: 44%;
`;

const DividerLineHorizontal = styled.div`
    width: 100%;
    height: 1px;
    background: radial-gradient(rgba(255, 255, 255, 0.96) 0%, rgba(0, 0, 0, 0) 70%);
    margin: 20px 0 15px 0px;
    margin-bottom: 0;
`;

const RightSection = styled(LeftSection)`
    padding-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: auto;

    & > img {
        width: 70%;
        height: 305px;
        margin: 10px;
        object-fit: contain;
    }
    & > svg {
        width: 70%;
        height: 70%;
    }
`;
const ChooseChannel = styled(ChooseDocument)`
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 10px;


    > label { 
        /* margin-bottom:15px;     */
        min-width: 150px;
        width: 48.7%;
        margin-right: 0;
        flex: 0 0 48.6%;

        &:hover input:not([disabled]) ~ .checkmark {
            background: ${theme('palette.UDBInputHoverColor')};
            border: ${theme('palette.UdbInputBorder')};
            /* color: #161137; */
            color: ${theme('palette.UDBInputChannelHoverColor')};
        }

        &:hover i {
            color: ${theme('palette.UDBCustomRadioBeforeTextSubNewHover')};
        }

        &:nth-child(odd) {
            margin-right: 0;
        }
    }
    .checkmark {    
        flex-direction: column;
        position: relative;
        align-items: flex-start;
        // padding-left: 2.5em;
        padding-left: 33px;
        color: ${theme('palette.UDBCustomRadioBeforeTextMain')};
        border: ${theme("palette.channelSelectedBorder")};
        border-radius: ${theme('palette.UDBCustomRadioBorderRadius')};
        background: ${theme('palette.UDBCustomRadioBgColor')};
        /* height: 56px; */
        height: ${theme('palette.UDBCustomRadioBtnHeight')};

        :before {
            content: "";
            position: absolute;
            width: 13px;
            height: 13px;
            border: ${theme('palette.UDBCustomRadioBeforeBorder')};
            left: 10px;
            border-radius: 20px;

            border: ${theme("palette.channelCheckmarkBorder")};
            background: ${theme("palette.channelCheckmarkBg")};
            background-clip: ${theme("palette.channelCheckmarkBgClip")};
            background-origin: ${theme("palette.channelCheckmarkBgClip")};

        }
    }

    .specialChannel {
        width: 100%;
        /* margin-bottom: 10px; */
        border: ${theme('palette.depositSpecialChannelBorder')};
        /* border-radius: 6px 6px 0px 0px; */
        border-radius: 6px;
        height: 78px;
        margin-right: 0;
        flex: auto;

        & .checkmark {
            /* border-radius: 6px 6px 0px 0px !important; */
            border-radius: 6px !important;
            height: 78px;
            padding-bottom: 1rem;
            /* justify-content: flex-start; */
            background: url(${config.imageV3NonOptHost}/static/cashsite/${theme('image.recommendedChannelBgImg')});
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .specialChannelSelected {
        width: 100%;
        /* justify-content: flex-start; */
        /* margin-bottom: 10px; */
        border: ${theme('palette.depositSpecialChannelActiveBorder')};
        /* border-radius: 6px 6px 0px 0px; */
        border-radius: 6px;
        margin-right: 0;
        flex: auto;

        & .checkmark {
            /* border-radius: 6px 6px 0px 0px !important; */
            border-radius: 6px !important;
        }

        input:checked ~ .checkmark {
            height: 78px;
            padding-bottom: 1rem;
            background: url(${config.imageV3NonOptHost}/static/cashsite/${theme('image.recommendedChannelCheckedBgImg')});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 100%;
            animation: pulse-shadow 4s infinite ease-in-out;

            @keyframes pulse-shadow {
            50% {
                box-shadow: -11px -10px 25px -11px #00ff2ee3, -4px 14px 16px -2px #7cb0fd8a, 8px -11px 22px -4px #ff0000a6, 12px 20px 27px -8px #e57dffad, -12px 20px 25px -11px #00ff2e80;
            }
            }
        }

        .redpoBonusText {
            color: ${theme('palette.UDBCustomRedpoBonusText')};
        }
    }

    input:checked ~ .checkmark {
        background: ${theme('palette.UDBInputHoverColor')};
        border: ${theme("palette.channelSelectedBorder")};
        color: ${theme('palette.UDBCustomRadioBeforeTextMain')};

        :before {
            background: ${theme('palette.secondary-background-color-linear-new1')};
            border: 2px solid rgb(255 255 255 / 97%);

            background: ${theme("palette.channelCheckedCheckmarkBfrBg")};
            border: ${theme("palette.channelCheckedCheckmarkBfrBorder")};
        }
        i {
            color: ${theme('palette.UDBCustomRadioBeforeTextSub')};
        }
    }
`;
const SecondaryText = styled.i`
    font-size: 12px;
    letter-spacing: 0.01em;
    font-style: normal;
    color: ${theme('palette.UDBCustomRadioBeforeTextSubNew')};
`;
const FakeInputWrapper = styled.span`
    position:absolute;
    width:0px;
    height:0px;
    top:-12000px;
    opacity:0;
`;

const ErrorMsg = styled.span`
    color: ${theme('palette.ErrorMsgColorRed')};
    font-size: .75rem;
    position: relative;
    top: -5px;
    width: 100%;
    text-align: center;
`

const NoChannelMsg = styled.div` 
color: ${theme('palette.ErrorMsgColor')};
display: flex;
justify-content: center;
text-transform: capitalize;
&.noCh { font-size:1.1em}
`
const SubTittle2 = styled(SubTittle)`
width:170px;
`

const FormAndUploadWrap = styled.div`
   width: 55%;
   display: flex;
   flex-direction: column;
   align-items: center;

   & .docUploadHead{
        justify-content: center;
        background: ${theme('palette.DepositHeadSecondary')};
        border-radius: 4.512px 4.512px 0px 0px;
        color: ${theme('palette.DepositTextColorPrimary')};
        font-size: 13px;
        width: 100%;
        margin-bottom: ${theme('palette.docUploadHeadMarginBtm')};
        margin-top: ${theme('palette.docUploadHeadMarginTop')};

        & p {
            font-size: 9px;
            color:  ${theme('palette.DepositTextColorPrimary')} !important;
            width: 155px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
   }
`;

const ManualDepositBankDetailsWrapper = styled.div`
   width: 100%;
   display: flex;
   align-items: flex-start;
   justify-content: space-around;

   & .fastBankDetailsCopy {
    display:flex;
    justify-content: space-between;
    margin-bottom: 5px;
    margin-top: 5px;
   }

   & .manualDepositBankDetailsWrap {
        padding: 10px;
        padding-top:0px;
        display: flex;
        flex-direction: column;
        width: 45%;
        align-items: center;
        /* justify-content: center; */
   }

   & .manualDepositBankDetailsWrap2 {
        padding: 10px;
        padding-top:0px;
        display: flex;
        flex-direction: column;
        width: 45%;
        align-items: center;
        justify-content: center;
   }

   & .fastBankFormWrap {
      width: 45%;
      padding: "20px 10px";
   }
`
const ManualDepositWrapp = styled.div`
width: 100%;
height: auto;
text-transform: capitalize;
text-align: center;
border-radius: 9.945px;
background: ${theme('palette.ManualDepositWrapBg')};
`
const TextWrap = styled.h2`
font-weight: bold;
font-size: 16px;
align-items: center;
letter-spacing: 0.03em;
text-transform: capitalize;
color:  ${theme('palette.DepositTextColorPrimary')};
`
const SubTittleWrap = styled(SubTittle)`
    padding: 7px 0px 5px 5px;
    text-transform: capitalize;
    text-align: center;
    white-space: nowrap;
    font-size: 15px;
    margin: 3px 0px 0px 0px;
    font-weight: 500;

    & > p  {
        color:  ${theme('palette.DepositTextColorPrimary')};
        margin: 3px 5px; 
        font-size: 12px;
    }
`
const UploadDiv = styled(Upload)`
    /* margin-left: 8px; */
    width: 100%;
    padding: 0rem 0rem 1rem 0rem;
    /* min-height: 200px; */
    min-height: ${theme('palette.UDBUploadDivMinH')};
    border: ${theme('palette.UDBUploadDivBorder')};
    background: ${theme('palette.UDBUploadBgColor')};
 

    & svg {
        z-index: 999;
    }
    & span {
        z-index: 999;
    }
   
    .cfstyle {
        background-size: contain; 
        background-position-x: ${theme('palette.UDBUploadBgPosX')};
    }

    & input {
        height: 115px;
        cursor:pointer;
    }

    & span {
        z-index: 0;
    }
`
const ManualDespositSec = styled.div`
   display: flex;
   width: 100%;
   padding: 15px 8px;
   justify-content: space-around;

   & .manualUpi2 {
     justify-content: flex-start;
   }

   & .manualUpi1 {
     justify-content: flex-start;
   }
`
const AccountHolder = styled.div`
    font-size: 10px;
    line-height: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    color: ${theme('palette.DepositTextColorPrimary')};
    justify-content: center;
    align-items: flex-start;
    margin: 3px 0px;
    width: auto;
    min-height: 50px;

    @media only screen and (max-width: 1300px){
        min-height: 70px;
    }

    & > h6 {
        margin: 5px 0px;
        font-size: 11px;
        color: ${theme('palette.DepositAccountHolderLabel')}; 
        /* position: ${theme('palette.depositAccountHolderLabelPosition')}; */
        top: 0;
        margin: 0;  
    }

    & > p {
        margin: 5px 0px;
        font-size: 11px;
        color: ${theme('palette.DepositTextColorPrimary')};
    }
`
const TextInside = styled.h1`
    background:  ${theme('palette.DepositHeadPrimary')};
    font-size: 15px;
    font-weight: bold;
    color: ${theme('palette.DepositTextColorPrimary')};
    padding: 5px;
    margin-top: ${theme('palette.DepositTextInsideMargTop')}; 
    letter-spacing: 0.03em;  
    border-radius: 4.512px 4.512px 0px 0px;
    width: 100%;
`;

const AccountDetailsWrap = styled.span`
    font-size: 12px;
    display: flex;
    justify-content: flex-start;
    letter-spacing: 0.05em;
    font-weight: bold;
    align-items: center;
    /* padding-left: 15px; */
    line-height: 1.2rem;

& > p {
    font-size: 13px;
    text-align: left;
    margin: 3px 0px;
    width: 125px;
    text-overflow:ellipsis;
    overflow:hidden;
    /* // Addition lines for 2 line or multiline ellipsis
    display: -webkit-box !important;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: normal; */
    line-break: auto;
    
    @media only screen and (max-width: 1400px){
         font-size: 13px;
         width: 108px;
       }
    @media only screen and (max-width: 1300px){
        font-size: 13px;
        width: 90px;
    }
}
`

const ManualDepositBankDetails = styled.div`
   width: 100%;
   max-height: auto;
   background-size: cover;
   background-repeat: no-repeat;
   text-align: center;
   border: ${theme('palette.mnanualDepositBankDetailsNonActiceBorder')};
   background-position: center;
   margin: 8px 7px;
   border-radius: 5px;
   position:relative;

    &.classBorderChecked {
        border: ${theme('palette.mnanualDepositBankDetailsActiceBorder')};
    }

    /* &.classBorder {
        background: ${theme('palette.manualDepositBankDetailsInActiceBgColor')};
        backdrop-filter: ${theme('palette.manualDepositBankDetailsInActiceBgBlur')};        
    } */

   &.pos-0 {
    margin-top:5px;
   }
   flex-shrink: initial;
    /* min-width: 425px; */

   & ${TextInside} {
      margin: 0px 0px;
      border-radius: 5px 5px 0px 0px;
      font-size: 13px;
      font-weight: 500;
      font-weight: ${theme('palette.DepositTextInsideWeight')};
   }

   & .fastBankDetailsCopyInner{
       width: 49%;
       padding-left: 5%;

       @media only screen and (max-width: 1400px){
          padding-left: 3%;
       }
   }

   & ${AccountHolder} {
      align-items: flex-start;
      position: relative;

      & span {
        padding: 0px;
      }
   }

   & ${AccountDetailsWrap} {
      /* height: 40px; */
      .lf-player-container {
        margin-top: ${theme('palette.copyImagePosition')};
    }
   }
`;

const OptOverlay = styled.div`
    position:absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${theme('palette.DepositTextColorPrimary')};
    background: rgb(0 0 0 / 71%);
    border-radius: 5px;
    cursor: pointer;
    font-weight: 900;
    background: ${theme('palette.manualDepositBankDetailsInActiceBgColor')};
    backdrop-filter: ${theme('palette.manualDepositBankDetailsInActiceBgBlur')};  
`;
const ManualDepositUPIDetails = styled.div`
   max-width: 100%;
   /* width: 80%; */
   width: ${theme('palette.manualDepositUPIDetailsWidth')};
   min-width: 200px;
   max-height: 160px;
   background-size: cover;
   background-repeat: no-repeat;
   text-align: center;
   border:0.2px solid lightgray;
   border-radius: 5px;
   margin: 7px 7px;
   background-position: center;
   position:relative;  

   & ${TextInside} {
      margin-top: 0px;
      border-radius: 5px 5px 0px 0px;
      font-size: 13px;
      font-weight: 500;
      font-weight: ${theme('palette.DepositTextInsideWeight')};
      margin-bottom: 5px;
   }

   & ${AccountHolder} {
     align-items: center;
   }

   & ${AccountDetailsWrap} {
    font-size: 13px;
   }
`
const QRcodeWrapper = styled.div`
    width: 70%;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 10px 0px;
    position: relative;

    p {
        color: #9C96B2;
        font-size: 9px;
        letter-spacing: 0.01em;
    }
`;

const QRCodeText = styled.span`
    color: ${theme('palette.DepositTextColorPrimary')};
    font-size: 16px;
    padding: 5px 0px;
    text-transform: capitalize;
`

const QRCodeBox = styled.div`
background: ${theme('palette.DepositTextColorPrimary')};
margin: 5px 10px 15px;
padding: 10px;
`

const ManualDepositUPILeftWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 45%;
    align-items: center;
    position:relative;
    justify-content: center;
`

const DepositInfoWrapper = styled.div`
  display:flex;
  justify-content: flex-start;
  width: 95%;
`

const DepositInfo = styled.p`
    font-size: .7rem !important;
    text-transform: capitalize;
    color: #ffffff !important;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0px;
    font-weight: 700;
`;

const NoteMsg = styled.div`
    font-size: .75rem;
    font-style: oblique;
    color:${theme('palette.DepositTextColorPrimary')};
`;

const DepositTopSecWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    border-radius: 9.945px;
    background: ${theme('palette.UdbDepositTopSecWrapperBgColor')};
    padding: ${theme('palette.UdbDepositTopSecWrapperPadding')};
    margin-bottom: ${theme('palette.UdbDepositTopSecWrapperMarginBtm')};

    & .customWidthLeft {
        width: 60%;
        padding-top: 1em;
        position: relative;
    }
    
    & .customWidthRight {
        width: 40%;
    }
`
const DepositTopSecLeft = styled.div`
    width: 50%;    

    & .docMargin{
        margin-bottom: 0;
        z-index: 1;
        position: relative;
    }
`
const DepositTopSecRight = styled.div`
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    align-items: flex-start;

    & svg {
        width:90%;
      

    @media only screen and (max-width: 1520px){
        width: 85%;
    }

    @media only screen and (max-width: 1420px){
        width: 75%;
    }
    }
   
`
const RegularDepositLeftWrap = styled.div`
   display:flex;
   flex-direction: column;

   & .regularDepositLeftSec {
        /* border: 1px solid ${theme('palette.DepositTextColorPrimary')}; */
        border: 1px solid ${theme('palette.regularDepositLeftWrapBorder')};
        padding: 10px; 
        border-radius: 10px;
   }

   .cryptoClass .staticEnhance {
        label input {
            color: #fff !important;
            font-weight: bold;
        }

        select {
            color: #fff;
        }
   }

   .noML {
    margin-left: 1px;
   }
   .md_inputWrp{
    margin-left: 1px;
    width: 94%;
    padding-right: 2px;
   }
`
const Tips = styled.a`
color: rgb(255, 255, 255);
display: inline-block;
padding: 0 6px 0 4px;
border: 1px solid ${theme('palette.DepositTextColorPrimary')};
border-radius: 50%;
margin-left: 5px;
text-align: center;
position:relative;
>span {
    display:none
}
 &:hover span {
    display: inline-block;
    position: absolute;
    top: -11px;
    white-space: nowrap;
    right: -40px;
    background: ${theme('palette.DepositTextColorPrimary')};
    color: #000;
    padding: 2px 5px;
    border-radius: 3px;
}
`;

const GatewayProviderWrap = styled.div`
    display: flex;
    /* justify-content: center; */
    justify-content: ${theme('palette.gatewayProviderWrapPosition')};
    margin-top: 10px;

    & img{
        /* width: 88%; */
        width: ${theme('palette.gatewayProviderWrapImgWidth')};
        height: auto;
        object-fit: contain;
    }
`
const UpiScannerWrapper = styled.div`
   width: 100%;
   height: auto;
   display:flex;
   justify-content:center;
   margin: 5px 0px;

   & > p {
    margin: 3px 0px;
    font-size: 15px;
    color: ${theme('palette.DepositTextColorSecondary')};
    text-transform: capitalize;
    line-height: 15px;
   }

   & svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
    cursor:pointer;
   }
`
const FastUpiScanner = styled.div<Pick<DepositStyleProps, "scannerDisplay">>`
    display: ${(props) => (props.scannerDisplay ? "flex" : "none")};
    transition: 1000ms;
    transition-timing-function: ease-out;
    background: #1E1E1E;
    backdrop-filter: blur(25px);
    border-radius: 8px;
    padding: 5px;
    width: auto;
    height: 240px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 48%;
    top: 24%;
    z-index: 99999;

    & p {
        position: absolute;
        right: -23px;
        top: -17px;
        margin: 2px 0px;
        cursor:pointer;

        & svg {
            width: 17px;
            height: 18px;
        }
    }
`
const FastBankUpiBgTexts = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    width: 97%;
    margin: 5px 10px;

    & .optionSec {
        display: flex;
        align-items: center;

        & p {
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 30px;
        text-transform: capitalize;
        color: #ffffff;
        text-shadow: 0px 4.19149px 5.58866px rgba(0, 0, 0, 0.25);
        margin: 0px 3px;
    }
    }

     & input[type=checkbox] {
        accent-color: ${theme('palette.AuthCheckboxColorDepo')} !important;
        width: 18px;
        height: 16px;
      } 
`;

// const ExtraBonusWrp = styled.div`
//    position: relative;
//     height: 1px;
//     display: flex;
//     justify-content: center;
//     .svgWrapper {
//         position: absolute;
//         left: 8px;
//          top: -51px;
//         > svg {
//             width: 100%;
//         }
//     }
// `;

const ExtraBonusWrp = styled.div`
   position: relative;
    /* height: 18px; */
    display: flex;
    /* justify-content: center; */
    justify-content: flex-start;
    align-items: center;
    margin-top: -8px;
    z-index: 0;

    &.moreSpace {
        margin-top: -6px;
    }
`;

const ExtraBonusSec = styled.div`
    background: url(${config.imageV3NonOptHost}/static/cashsite${theme('image.extraBonusImageBg')});
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
    background-position: center;
    /* width: 170px;
    height: 35px; */
    width: 225px;
    height: 51px;
    /* position: absolute;
    top: 0;
    left: 0; */
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 13px;
    
    & h6 {
        color: #FFF;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        /* position: absolute;
        left: 18px;
        top: 18px; */
        letter-spacing: 0.4px;
        margin: 0px;

        & span {
            color: #FCEFA5;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-transform: uppercase;
        }
    }
`

const ExtraBonusSecSingle = styled.div`
    background: url(${config.imageV3NonOptHost}/static/cashsite${theme('image.extraBonusImageBgOne')});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* width: 120px;
    height: 40px; */
    width: 110px;
    height: 43px;
    /* position: absolute;
    top: 0;
    left: 0; */
    display: flex;
    align-items: flex-end;

    
    & h6 {
        color: #FFF;
        text-align: center;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        text-transform: uppercase;
        /* position: absolute;
        left: 0px;
        top: 16px; */
        letter-spacing: 0.4px;
        margin: 0px;
        padding-bottom: 5px;

        & span {
            color: #FCEFA5;
            text-align: center;
            font-size: 10px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            text-transform: uppercase;
        }
    }
`
const ChannelMissoutMsg = styled.div`
    background: linear-gradient(90deg, #FD8A03, #FDDD03, #FD8A03);
    backdrop-filter: blur(11.693467140197754px);
    border-radius: 7px;
    width: 100%;
    height: auto;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: space-between;

    & p {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
        margin: 3px 0px;
        text-align: center;
        letter-spacing: 0.3px;
    }
`;

// const ChannelMissoutMsg = styled.div`
//   background: linear-gradient(90deg, #FD8A03, #FDDD03, #FD8A03);
//   backdrop-filter: blur(11.693467140197754px);
//     width: 100%;
//     height: auto;
//     /* padding: 12px 0px; */
//     display: flex;
//     align-items: center;
//     gap: 5px;
//     border-radius: 7px;
//     justify-content: center;

//     & p {
//         color: #000;
//         font-size: 12px;
//         font-style: normal;
//         font-weight: 600;
//         line-height: normal;
//         text-transform: capitalize;
//         /* margin: 3px 0px; */
//         text-align: center;
//         letter-spacing: 0.3px;
//     }
// `