import React, { useState } from 'react'
import styled from 'styled-components';
import config from 'src/config/config';
import { SvgIcon } from '../commonStyle';
// import { Player } from '@lottiefiles/react-lottie-player';
// import TopChoiceDeposit from 'src/components/icon/TopChoiceDeposit';
import { theme } from 'styled-tools';
// import { important } from 'polished';
// import { MainWrapper } from 'src/pages/udb';

interface styledProps{
    topChoice : boolean
}

type customRadioButtonProps = {
  name: string;
  label: string | React.ReactNode;
  value: string;
  checked: boolean;
  icon: any;
  theme: any;
  topChoice: boolean;

  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: string) => void;
} & Omit<React.ComponentProps<'input'>, 'name' | 'onChange' | 'value'>

const CustomRadioDepositButton = ({ name, label, value, checked, icon, theme, topChoice, onChange, ...props }: customRadioButtonProps) => {  
    const classAnimate = topChoice ? 'showAnimation':'classic'

    return (
        <Container className={classAnimate} checked={checked}>
            <ContainerInputWrap className={classAnimate} checked={checked}>
                <input type="radio" name={name} value={value} checked={checked} onChange={(e) => onChange(e, value)} {...props} />
                <span className="checkmark">
                    {/* <RadioSvgIcon>{icon}</RadioSvgIcon> */}
                    <RadioPngIcon>
                        <img src={`${config.imageV3Host}/static/cashsite/${icon}`} alt="paymentmethodicons" />
                    </RadioPngIcon>
                    <p>{label}</p>
                </span> 
            </ContainerInputWrap>
        </Container>
    )
    }

export default CustomRadioDepositButton;

//styled component

export const Container = styled.label<Pick<customRadioButtonProps, "checked">>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-left: 0px;
    cursor: pointer;
    user-select: none;
    /* margin-right: .75em; */
    margin-right: 0;
    overflow:hidden;
    width:115px;
    height: 113px;
    border-radius: 8px;
    padding:4px;
    flex-shrink: 0;
    

    &.showAnimation {
        background: url(${config.imageV3NonOptHost}/static/cashsite${theme('image.DepositRadioActiveBgBorder')});
        background-repeat: no-repeat;
        background-size: 100%;
        border:none; 
        height: 115px;
        /* background: url(${config.imageV3NonOptHost}/static/cashsite${theme('image.DepositRadioActiveBgBorderYolo')}); */
        /* background-size: 125%; */
        background-size: ${theme('palette.DepositShowAnimationBgSize')};
        /* background-repeat: no-repeat; */
        background-repeat: ${theme('palette.DepositShowAnimationBgRepeat')};
        /* background-position: center; */
        background-position: ${theme('palette.DepositShowAnimationBgPosition')};;
    }

    @media (max-width: 1400px) {
        font-size: 13px;
    }

    input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    }

    .checkmark {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
    min-width: 45px;
    /* background: ${props => props.checked ? "" : theme('palette.DepositCustomRadioBg')}; 
    border-color: ${props => props.checked ? theme('palette.DepositTextColorSecondary') : theme('palette.DepositTextColorPrimary')} !important;
    border: 2px solid;
    border-radius: 19px; */

    & > p {
        font-size: 15px;
        color: ${theme('palette.DepositTextColorPrimary')};
        z-index: 999;
        margin: 5px 0px;
    }

    /* @media (max-width: 1400px) {
        height: 115px;
        width: 115px;
    } */

    }

    input:checked ~ .checkmark {
    box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
    color: ${theme('palette.DepositTextColorPrimary')};
    }

    .checkmark:after {
    content: "";
    position: absolute;
    display: none;
    }
`;

const ContainerInputWrap = styled.div<Pick<customRadioButtonProps, "checked">>`
    width:100%;
    height: 100%;
    position: relative;
    border-radius: 8px;
    /* background: ${props => props.checked ?`url(${config.imageV3NonOptHost}/static/cashsite${props.theme.image.DepositRadioActiveBg})` : theme('palette.DepositCustomRadioBg')}; */
    background-size:cover;
    border:1px solid;
    /* border:3px solid; */
    /* outline: ${(props) => (props.checked ? "4px solid #fff" : "1.275px solid #fff")}; */
    background-repeat: no-repeat;
    /* background-position: 0 0; */
    border-color: ${props => props.checked ? theme('palette.DepositTextColorSecondary') : theme('palette.DepositTextColorPrimary')};
    /* border-color: ${props => props.checked ? theme('palette.NewDepositTextColorSecondary') : theme('palette.DepositTextColorPrimary')}; */
    background: ${props => props.checked ?`url(${config.imageV3NonOptHost}/static/cashsite${props.theme.image.NewDepositRadioActiveBg})` : theme('palette.DepositCustomRadioBg')};
    /* background: linear-gradient(135deg, #5194EC 3.32%, #0537CD 103.8%); */
    background-position: center;
    border: ${props => props.checked ? theme('palette.containerInputWrapBorderChecked') : theme('palette.containerInputWrapBorder')};


    &.showAnimation {
        border:none;
        overflow:hidden;
        background-size:100%;
        border-radius: 10px;
        outline: none;

        ::before{
            content: "";
            background: url(${config.imageV3Host}/static/cashsite/${theme('image.topChoiceNew')});
            position: absolute;
            left: -18%;
            top: 5%;
            z-index: 99;
            width: 100px;
            height: 16px;
            background-size: contain;
            background-position: left;
            background-repeat: no-repeat;
            transform: rotate(-40deg);
        }
    }
  

    /* & div > div {
       position: absolute;
       z-index: ${(props) => (props.checked === true ? "5" : "99")};
       left: ${(props) => (props.checked === true  ? "-22px" : "-32px")};
       right: 0;
       top: ${(props) => (props.checked === true ? "-21px" : "-37px")};
       width: ${(props) => (props.checked === true  ? "168px" : "195px")};
       height: ${(props) => (props.checked === true ? "165px" : "195px")};
       mix-blend-mode: ${(props) => (props.checked === true ? "overlay" : "difference")};

       @media (max-width: 1400px) {
            width: ${(props) => (props.checked === true  ? "154px" : "180px")};
            height: ${(props) => (props.checked === true ? "152px" : "180px")};
            top: ${(props) => (props.checked === true ? "-19px" : "-35px")};
            left: ${(props) => (props.checked === true ? "-20px" : "-32px")};
        }
    }

    & span {
        z-index: ${(props) => (props.checked === true ? "9" : "-10")};
    }  */
`  
                                              
const RadioPngIcon = styled(SvgIcon)`
    height: 65px;
    width: 100px;
    min-width: 45px;
    display: flex;
    justify-content: center;
    padding: 20px;
    margin-right: 0px;

    /* & svg {
        width:65px;
        height: 65px;
        z-index: 9999;
    } */

    & img {
        width:65px;
        height: 65px;
        z-index: 9999;
    }
`
