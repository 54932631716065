import React from 'react'
import styled from 'styled-components';
import config from 'src/config/config';
import { theme } from 'styled-tools';

type customRadioButtonProps = {
  name: string;
  label: string | React.ReactNode;
  value: string;
  id?: string
  is_highlight?:boolean | number;
  bonus_amount? : string | number;
  activeChannel?: boolean;

  onChange: (e: React.ChangeEvent<HTMLInputElement>, value?: string) => void;
} & Omit<React.ComponentProps<'input'>, 'name' | 'onChange' | 'value'>


type StyleProps = {
  activeChannel: boolean;
}


const CustomRadioButton = ({ name, label, value,is_highlight, bonus_amount,className, activeChannel, id='', onChange, ...props }: customRadioButtonProps) => {
  return (
    <Container className={className}>
      <input type="radio" name={name} value={value}  onChange={(e) => onChange(e, value)} {...props} />
      <span id={id} className="checkmark">{label}</span>
      {is_highlight && bonus_amount ? <HighlightBonusWrapper activeChannel={activeChannel} className='redpoBonusText'>Get {bonus_amount}% REDEPOSIT BONUS</HighlightBonusWrapper> : <></>}
      {is_highlight && bonus_amount ? <RecomendedWrapper>Recommended</RecomendedWrapper> : <></>}
    </Container>
  )
}

export default CustomRadioButton;

export const Container = styled.label`
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-left: 0px;
  cursor: pointer;
  user-select: none;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  margin-right: 1em;

input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
  // position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  // top: 0;
  // left: 0;
  height: 45px;
  width: 100%;
  background: #ffffff;
  border: ${theme("palette.channelSelectedBorder")};
  /* box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4); */
  border-radius: 3px;
}
&:hover input:not([disabled]) ~ .checkmark {
  background: ${theme('palette.secondary-background-color-linear-new1')};
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
  color: #fff;
}
input:checked ~ .checkmark {
  background: ${theme('palette.secondary-background-color-linear-new1')};
  border: 1px solid #FFFFFF;
  box-shadow: 0px 0px 40px rgba(16, 11, 73, 0.4);
  color: #fff;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

input:checked ~ .checkmark:before {
  background: #e42a09;
}
`

const HighlightBonusWrapper = styled.span<Pick<StyleProps, "activeChannel">>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 3px 2px;
  position: absolute;
  /* top: 44px; */
  bottom: -1px;
  text-transform: none !important;
  background: ${props => (props.activeChannel) ? theme('palette.depositSpecialChannelNoteBGActive') : theme('palette.depositSpecialChannelNoteBG')};
  color: ${props => (props.activeChannel) ? theme('palette.depositSpecialChannelNoteTextActive') : theme('palette.DepositTextColorPrimary')};
  border-radius: 0px 0px 6px 6px;
  font-size: 12px !important;
  font-weight: 600;
  letter-spacing: 0.2px;
`

const RecomendedWrapper = styled.span`
    display: flex;
    width: auto;
    align-items: center;
    justify-content: center;
    padding: 2px;
    position: absolute;
    top: 22px;
    font-size: 11px !important;
    font-weight: 700;
    border-radius: 1.989px;
    background: linear-gradient(90deg, #A30101 8.6%, #E40101 33.41%, #D3160D 59.1%, #FC2B19 81.14%, #9B0101 91.25%);
    color: #FCEFA5;
    text-align: center;
    padding: 3px 5px;
    text-transform: uppercase !important;
    letter-spacing: 0.5px;
    right: 10px;
`